import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { web_129, web_130, web_131, web_132 } from '../../api/api';
import useSystemText from '../../helper/useSystemText';
import { Dropdown } from 'primereact/dropdown';
import HelpIcon from '../../components/UI/helpIcon';
import useHelpText from '../../helper/useHelpText';
import { ErrorMessage, useFormik } from 'formik';

const MediatorSetting = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)  
  const location = useLocation()

  const [selectedMediator, setSelectedMediator] = useState("")
  const [mediatorOption, setMediatorOption] = useState([])
  const [gatewayDataList, setGatewayDataList] = useState([])
  const [gatewayCheckboxList, setGatewayCheckboxList] = useState([])
  
  const sysText711001 = useSystemText(711001)
  const sysText711002 = useSystemText(711002)
  const sysText711003 = useSystemText(711003)
  const sysText711004 = useSystemText(711004)

  

  const helpText20 = useHelpText(20)  

  const mediatorOnChangeHandler = (e) => {
    setSelectedMediator(e.value)
  }

  const gatewayCheckboxOnChangeHandler = (e, id) => {
    if (e.target.checked) {
      // Add the id to the list
      setGatewayCheckboxList(prevList => [...prevList, id]);
    } else {
      // Remove the id from the list
      setGatewayCheckboxList(prevList => prevList.filter(item => item !== id));
    }
  }

  const formik = useFormik({
    initialValues: {       
      percent: "",            
    },
    validate: (data) => {
      let errors = {};      

      const percentRegExp = /^(100(\.0{0,4})?|0(\.\d{1,4})?|[1-9]\d?(\.\d{1,4})?)$/;
     
      if(data.percent == "" || (data.percent && !percentRegExp.test(data.percent))) {
        errors.percent = "必須為 0.0000 ~ 100.0000"
      }

      return errors;
    },
    onSubmit: async(data) => { 
      let obj = {} 
      gatewayCheckboxList.map((row) => {        
        obj[row] = data.percent
      })      

      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        data: JSON.stringify(obj)       
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_132(filterData);       
        
        

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                   
          showToast("success", "" + status, `${sysText711003}` )           
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	   

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }    
  }

  useEffect(() => {        
    
    const fetchMediatorData = async() => {    
      setIsLoading(true)   
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),                 
      };
      try {
        const { listResponse, status, statusText } = await web_129(filterData);        
        
        

        if (status === 200) {      
          const mediatorData = listResponse.map(item => ({name: `${item.uuid} - ${item.username}`, value: `${item.uuid}`}))               
          setMediatorOption(mediatorData)            
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    fetchMediatorData()
  }, [isRefresh]);   

  useEffect(() => {    
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      mediator: selectedMediator
    };

    const fetchGatewayData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_131(filterData);        
        
        

        if (status === 200) {                     
          setGatewayDataList(listResponse)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    if(selectedMediator) {
      fetchGatewayData(filterData)
    }    
    
  }, [selectedMediator, isRefresh]);

  return (
    <form onSubmit={formik.handleSubmit} className="p-4 max-md:px-0 max-xl:py-2 recordWrap tableWrap h-full">
      <div className='flex flex-wrap mb-12 h-12'>        
        <div className='flex flex-row items-center max-2xl:w-[100%] space-x-4 px-2'>      
          <HelpIcon tooltipText={helpText20}/>       
          <div className='w-full flex flex-row min-xl:space-x-2 max-xl:flex-col max-xl:items-center max-xl:space-y-2'>                       
            <Dropdown className='custom-dropdown min-w-48' name="selectedMediator" value={selectedMediator} options={mediatorOption} optionLabel="name" optionValue="value" filter onChange={(e) => {mediatorOnChangeHandler(e)}} placeholder={sysText711001}></Dropdown>          
          </div>  
        </div>                        
      </div>

      <div className='flex flex-col p-4 w-full h-[calc(100%-8rem)] overflow-auto'>
      {Object.entries(gatewayDataList).map(([mechant, list]) => (      
        <>                     
          <div className='text-yellow-300'>{mechant}</div>
          <div className='p-4 flex flex-row flex-wrap'>
            {list.map((row) => (
              <div className='w-[33%] max-xl:w-[50%] max-md:w-full py-4'>
                <div className='flex items-center space-x-4 w-full'>
                  <input name="gatewayCheckbox" className='' value={row.id} type='checkbox' checked={gatewayCheckboxList.includes(row.id)} onChange={(e) => gatewayCheckboxOnChangeHandler(e, row.id)}></input>
                  <div className='w-48'>{row.display_name}</div>
                  <div>{row.mediator_rate_percent}<span>%</span></div>              
                </div>
              </div>
            ))}           
          </div>
        </>
      ))}
      </div>

      <div className='flex flex-row min-xl:space-x-2 max-xl:flex-col max-xl:justify-center w-full items-center'>
        <div className='relative'>
          <input className='w-48' name="percent" type="text" placeholder='0.000 ~ 100.000' autoComplete='off' value={formik.values.percent} onChange={formik.handleChange} />          
          <div className='absolute top-2 right-2'>%</div>
        </div>
        <Button severity='info' className='w-24 max-xl:w-48' type="submit" label={sysText711004} disabled={!(gatewayCheckboxList.length > 0) || formik.values.percent == ""}></Button>
      </div>
    </form>
  );
}

export default MediatorSetting


