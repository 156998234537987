import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// import MenuData from '../data/menuData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { classNames } from 'primereact/utils';
import MenuContext from '../context/menuContext';
import ToastContext from '../context/ToastContext';


const Breadcrumb = () => {
  const location = useLocation()
  const path = location.pathname  
  const {menuData} = useContext(MenuContext)
  const {isRefresh} = useContext(ToastContext)
  const [items, setItems] = useState([])
  // const [home, setHome] = useState({ label: "Home", url: '/home', className: "font-bold" })  
  
  const setBreadcrumb = (data, path) => {
    const breadItems = []
    
    data?.map(row => {      
      if(row.link !== "/home"){
        if(path.includes(row.link)) {
          breadItems.push({ label: row.label, icon: row.icon })
          
          row.items?.map(item => {
            if(path === item.link) {
              breadItems.push({ label: item.label, icon: item.icon})
            }
          })
        }
      }
     
    })
    setItems(breadItems)
  }

  useEffect(() => {
    setBreadcrumb(menuData, path)
  },[path, isRefresh])

  // const items = [{ label: 'Computer' }, { label: 'Notebook' }, { label: 'Accessories' }, { label: 'Backpacks' }, { label: 'Item' }];
  // const home = { icon: 'pi pi-home', url: 'https://primereact.org' }

  return (
    <BreadCrumb model={items} home={menuData[0]} />
  )
}

export default Breadcrumb;
