import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { web_65_withdrawal_yesorno } from '../../../api/api';

const WithdrawRecordComfirm = (props) => {

  const {setShowConfirm, confirmData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation() 

  const exceptionHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      id: confirmData.id,
      state: "3"
    }
    try {
      const {listResponse, status, statusText} = await web_65_withdrawal_yesorno(filterData)

      if (status === 200) {   
        setIsRefresh(prev => !prev)      
        showToast("success", "" + status, `成功修改提领记录 编号:${confirmData?.編號}` )          
        setShowConfirm(false)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }

  const submitHandler = () => {      
    
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      id: confirmData.id,
      state: "2"
    };    

    const completeOrder = async () => {       
      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_65_withdrawal_yesorno(filterData);       
        
        

        if (status === 200) {
          setIsRefresh(prev => !prev)                     
          showToast("success",  "" + status, `成功修改提领记录 编号: ${confirmData.編號}` )
          setShowConfirm(false)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    completeOrder()         
  }  

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[250px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>变更状态</div>
            <div className='p-6 space-y-4 flex-1'>              
              <p className=''>确认变更提领单号:</p>
              <p className='text-yellow-500'>{confirmData?.編號}</p>             
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label='取消' severity='secondary' raised onClick={() => setShowConfirm(false)}></Button>
              <Button style={{width: '120px'}} label='驳回' severity='danger' raised onClick={exceptionHandler}></Button>
              <Button style={{width: '120px'}} label='确认' onClick={submitHandler} severity='info' raised></Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default WithdrawRecordComfirm;