import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import FilterInput from '../../components/UI/filterInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { web_99_get_block_list } from '../../api/api';
import RiskControlAdd from '../../components/other/riskControl/riskControlAdd';
import RiskControlEdit from '../../components/other/riskControl/riskControlEdit';
import RiskControlSetting from '../../components/other/riskControl/riskControlSetting';
import useSystemText from '../../helper/useSystemText';

const RiskControl = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [showAddRiskControl, setShowAddRiskControl] = useState(false)  
  const [showEditRiskControl, setShowEditRiskControl] = useState(false)
  const [showRiskControlSetting, setShowRiskControlSetting] = useState(false)
  const [riskControl, setRiskControl] = useState([])
  const [rowData, setRowData] = useState([])
  const [filterNumber, setFilterNumber] = useState("") 
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const [info, setInfo] = useState(null)
  
  const sysText706001 = useSystemText(706001)
  const sysText706002 = useSystemText(706002)
  const sysText706003 = useSystemText(706003)
  const sysText706004 = useSystemText(706004)
  const sysText706005 = useSystemText(706005)
  const sysText706006 = useSystemText(706006)
  const sysText706007 = useSystemText(706007)
  const sysText706008 = useSystemText(706008)
  const sysText706009 = useSystemText(706009)

  const blockTimeColumn = (item) => {
    return (
      <>
        <div>{item.ttl === "-1" ? "永久": item.ttl}</div>
      </>
    )
  }

  const riskControlColumn = (item) => {
    let label = ""

    switch(item.brushing_type) {
      case "1":
        label = "付款人姓名黑名單"
        break

      default:
        label = item.brushing_type
    }

    return (
      <>
        <div>{label}</div>
      </>
    )
  }
  
  const infoColunm = (row) => {
    return (
    <div>      
      <Button label={sysText706005} severity='info' text raised onClick={(e) => {setInfo(row?.blocked_orders)}}/>            
    </div>
    )    
  }

  const InfoDialog = ({ info, setInfo }) => {
    if (info === null) {
      return null;
    }
    //扁平化陣列
    function flattenArray(array) {
      // 检查输入是否为null或undefined
      if (!array) return [];  
      let result = [];  
      // 定义一个递归函数来处理扁平化逻辑
      function flatten(item) {
          if (Array.isArray(item)) {
              // 如果当前项是数组，遍历数组并递归调用flatten
              item.forEach(flatten);
          } else {
              // 如果当前项不是数组，直接添加到结果数组中
              result.push(item);
          }
      }  
      // 开始处理传入的array
      flatten(array);
  
      return result;
    }

    return (
      <Dialog
        onHide={() => setInfo(null)}
        header={sysText706005}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText706005}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {info.map((item, index) => 
              <p key={index}>{item}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const activeColumn = (item) => {

    const editHandler = () => {
      setShowEditRiskControl(true)
      setRowData(item)
    }

    return (
      <>
        <Button label={sysText706007} severity='warning' onClick={editHandler} raised></Button>
      </>
    )
  }

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")           
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_99_get_block_list(filterData);        
        
        

        if (status === 200) {  
          let newData = Object.entries(listResponse).map(([key, value]) => {return {"風控數據": key, ...value}})
          
          //搜尋用
          newData = newData.filter(item => item.風控數據.includes(filterNumber))
          setRiskControl(newData)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [isRefresh, filterNumber]);   

  return (  
     
    <div className='flex flex-col'>       
      <FilterInput setFilterNumber={setFilterNumber} showCalendarInput={false} placeholder="请输入风控数据"/>
      <div className='flex flex-row flex-wrap min-md:justify-end mb-1 items-center mx-4 space-x-2'>
        {(permissions === 3 || permissions === 9) &&
          <>
            <Button label={sysText706008} severity='help' className='mx-1 max-md:w-full' onClick={() => {setShowRiskControlSetting(true)}}></Button>           
            <Button label={sysText706009} severity='info' className='mx-1 max-md:w-full' onClick={() => {setShowAddRiskControl(true)}}></Button>
          </>
        }
           
      </div>             

      <div className="px-2 tableWrap">        
              
        <DataTable value={riskControl} scrollable scrollHeight='550px'>          
          <Column style={{maxWidth: "150px" }} field="風控類型" header={sysText706001} body={riskControlColumn}></Column>
          <Column style={{maxWidth: "200px" }} field="風控數據" header={sysText706002}></Column>
          <Column style={{maxWidth: "200px" }} field="封鎖時間" header={sysText706003} body={blockTimeColumn}></Column>
          <Column style={{maxWidth: "300px" }} field="note" header={sysText706004}></Column>
          <Column style={{maxWidth: "500px" }} field="" header={sysText706005} body={infoColunm}></Column>
          <Column style={{maxWidth: "100px" }} field="操作" header={sysText706006} body={activeColumn}></Column>
        </DataTable>          
          
        {/* <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> */}

        {/* 相關資訊 */}
        {info && <InfoDialog info={info} setInfo={setInfo}/>}

        {/* 創建風控資訊 */}
        {showAddRiskControl && <RiskControlAdd  setShowAddRiskControl={setShowAddRiskControl} />}

        {/* 編輯風控資訊 */}
        {showEditRiskControl && <RiskControlEdit setShowEditRiskControl={setShowEditRiskControl} rowData={rowData} />}

        {/* 风控规则设定 */}
        {showRiskControlSetting && <RiskControlSetting setShowRiskControlSetting={setShowRiskControlSetting}/>}
      </div>    
      
    </div>
   
  );
}

export default RiskControl;