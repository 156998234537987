import React, {useContext, useEffect, useState} from 'react';
import  Dialog from '../../dialog'
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_117_ncc_edit_percen, web_58_get_revenue_sharing } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';
import { useFormik } from 'formik';

const BatchEditPercent = (props) => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {batchEditGatewayData, setShowBatchEditGateway, setExpandedRows} = props
  const [gatewayShareData, setGatewayShareData] = useState([])

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText502001 = useSystemText(502001)
  const sysText502018 = useSystemText(502018)
  const sysText502019 = useSystemText(502019)
  
  const findMinShare = (data) => {
    if(Array.isArray(data)) {
      let minValue = 100
      data.forEach((item) => {
        minValue = parseFloat(item.趴數) < parseFloat(minValue) ? item.趴數 : minValue
      }, 100)     
      
      return minValue
    }    
    return "error"
  }
  
  useEffect(() => {
    const fetchGateway = async() => {
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        search_uuid: batchEditGatewayData.上層      
      };   
  
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_58_get_revenue_sharing(filterData);        
        
        
  
        if (status === 200) {     
          setGatewayShareData(listResponse)                    
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      } 

    }
    fetchGateway()
   
  }, [batchEditGatewayData])
  
     
  const formik = useFormik({
    initialValues: {
      sharePercen: 0,           
    },

    validate: (data) => {
        let errors = {};    
        const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/ 
       
        if (data.sharePercen != null && percentRegExp.test(data.sharePercen)) {								
          if (parseFloat(data.sharePercen) > parseFloat(findMinShare(gatewayShareData))) {								
            errors.sharePercen = '不可超过最大值';
          }
        }else {
          errors.sharePercen = '必须为数字';
        }     
      
        return errors;
    },     
    
    onSubmit: async(data) => {
      setIsLoading(true)  
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        percen_uuid: batchEditGatewayData.uuid,
        percen: formik.values.sharePercen
      }
            
      try {
        const { listResponse, status, statusText} = await web_117_ncc_edit_percen(filterData);          
                  
  
        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowBatchEditGateway(false)         
          //設定完後將下拉拓展關閉，避免顯示舊資料 
          setExpandedRows(null)
          showToast("success", "" + status, `成功更新分潤: ${batchEditGatewayData.uuid}` )
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }       
      
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  

  return (
    <Dialog>
      <div className='mx-auto w-[35vw] max-md:w-full min-h-[300px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown} className='flex flex-col'>
          <div className='flex items-center p-6 border-b border-gray-700'>
            <h2 className='text-lg'>{sysText502018}</h2>
          </div>                  
         
            <div className='leading-9 p-7 text-sm'>
              <p>{sysText502001}：{batchEditGatewayData.uuid}</p>            
              <label>{sysText502018}：　<small className='text-red-500'>{sysText502019}: {findMinShare(gatewayShareData)} </small></label>
              <input type="text" name="sharePercen" value={formik.values.sharePercen} onChange={formik.handleChange} className='w-full' autoComplete='off'></input>
              {getFormErrorMessage('sharePercen')}
              
            </div>  
            
            <div className='flex flex-auto w-full justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{minWidth: "120px"}} className='flex justify-center' label={sysText10003} onClick={() => {setShowBatchEditGateway(false)}} size="small" severity="secondary" raised></Button>
              <Button type="submit" style={{minWidth: "120px"}} className='flex justify-center' label={sysText10002} size="small" severity="info" raised></Button>
            </div> 
        </form>
        
      </div>
    </Dialog>
  );
};

export default BatchEditPercent;