import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

const PaginatorUI = (props) => {

  const {first, setFirst, rows, setRows, page, setPage, totalCount} = props.value

  const [dropdown, setDropdown] = useState(10)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInputTooltip, setPageInputTooltip] = useState("Press Enter");

  const onPageChange = (event) => {   
    setFirst(event.first)
    setRows(event.rows);
    setPage(event.page);      
  }    

  const onPageInputChange = (event) => {
      setCurrentPage(event.target.value);
    };

    // page從0開始但 currentPage 從1開始輸入
    const onPageInputKeyDown = (event, options) => {
      if (event.key === 'Enter') {
          const _page = parseInt(currentPage - 1);

          if (_page < 0 || _page > options.totalPages) {
              setPageInputTooltip(`1 ~ ${options.totalPages}`);
          } else {
              let _first = first;

              _first = currentPage - 1 ? options.rows * (_page) : 0;

              setFirst(_first);
              setPage(_page)
              setPageInputTooltip(`Press Enter 1 ~ ${options.totalPages}`);
          }
      }
    };

  const dropdownHandler = (e) => {
    setDropdown(e.target.value)
    setRows(e.target.value)
  }

  const totalPageCount = totalCount % rows == 0 ? parseInt(totalCount / rows) : parseInt(totalCount / rows) + 1

  const prevTenPageHandler = (options) => {

    const newPage = page - 10;
    
    if(newPage > totalPageCount)
    {
      setPage(totalPageCount - 1) //page從0開始
      setFirst((totalPageCount - 1) * rows)
    }else if((newPage < totalPageCount) && newPage > 0){
      setPage(newPage);       
      setFirst(newPage * rows);
    }else if(newPage < 0) {
      setPage(0)
      setFirst(0)
    }
    
  }

  const nextTenPageHandler = (options) => {
    const newPage = page + 10;
    if(newPage > totalPageCount)
    {
      setPage(totalPageCount - 1 ) //page從0開始
      setFirst((totalPageCount - 1) * rows)
    }else if((newPage < totalPageCount) && newPage > 0){
      setPage(newPage);       
      setFirst(newPage * rows);
    }else if(newPage < 0) {
      setPage(0)
      setFirst(rows)
    }
    
  }

    const template1 = {
      layout: 'FirstPageLink PageLinks LastPageLink',
      // PrevPageLink: (options) => {
      //     return (
      //         <button type="button" className="p-0" onClick={options.onClick} disabled={options.disabled}>
      //             <span className="">＜</span>
      //             <Ripple />
      //         </button>
      //     );
      // },
      // NextPageLink: (options) => {
      //     return (
      //         <button type="button" className="p-0" onClick={options.onClick} disabled={options.disabled}>
      //             <span className="p-3">＞</span>
      //             <Ripple />
      //         </button>
      //     );
      // },

      FirstPageLink: (options) => {
        return (
          <button type="button" className={options.className} onClick={() => {prevTenPageHandler(options)}}>
            <i className="pi pi-angle-double-left" style={{ fontSize: '1rem' }}></i>{options.last}
          </button>
        );
      },
      
      LastPageLink: (options) => {
        return (
          <button type="button" className={options.className} onClick={() => {nextTenPageHandler(options)}}>
            <i className="pi pi-angle-double-right" style={{ fontSize: '1rem' }}></i>
          </button>
        );
      },
      
      PageLinks: (options) => {
          // if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
          //     const className = classNames(options.className, { 'p-disabled': true });

          //     return (
          //         <span className={className} style={{ userSelect: 'none' }}>
          //             ...
          //         </span>
          //     );
          // }

          return (
              <button type="button" className={options.className} onClick={options.onClick}>
                  {options.page + 1}
                  <Ripple />
              </button>
          );
      },
    //   RowsPerPageDropdown: (options) => {
        
    //       const dropdownOptions = [
    //           { label: 10, value: 10 },
    //           { label: 20, value: 20 },
    //           { label: 30, value: 30 }
              
    //       ];

    //       return (<div className="max-md: hidden">
    //         <Dropdown value={dropdown} options={dropdownOptions} onChange={dropdownHandler} />
    //       </div>)
    //   },
      // CurrentPageReport: (options) => {
      //     return (
      //         <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
      //            到 <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange} /> 頁
      //         </span>
      //     );
      // }
  };

  return (
    <>
      <Paginator style={{height: "2rem", padding: "0px"}} template={template1} first={first} rows={rows} totalRecords={totalCount} onPageChange={onPageChange} />
    </>     
  
  );
};

export default PaginatorUI;