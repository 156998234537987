import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemText from '../helper/useSystemText';


const MenuContext = React.createContext();

export const MenuProvider = (props) => {   
    const location = useLocation()
    const navigate = useNavigate()

    const [showMenu, setShowMenu] = useState(false);
    const [showMenuList, setShowMenuList] = useState([])
    
    const sysText100000 = useSystemText(100000)

    const sysText200000 = useSystemText(200000)
    const sysText201000 = useSystemText(201000) 
    const sysText202000 = useSystemText(202000)
    const sysText203000 = useSystemText(203000)
    const sysText204000 = useSystemText(204000)
    const sysText205000 = useSystemText(205000)
    const sysText206000 = useSystemText(206000)
    const sysText207000 = useSystemText(207000)
    const sysText208000 = useSystemText(208000)

    const sysText300000 = useSystemText(300000)
    const sysText301000 = useSystemText(301000)
    const sysText302000 = useSystemText(302000)
    const sysText303000 = useSystemText(303000)
    const sysText304000 = useSystemText(304000)
    const sysText305000 = useSystemText(305000)
    const sysText306000 = useSystemText(306000)
    const sysText307000 = useSystemText(307000)
    const sysText308000 = useSystemText(308000)
    const sysText309000 = useSystemText(309000)

    const sysText400000 = useSystemText(400000)
    const sysText401000 = useSystemText(401000)
    const sysText402000 = useSystemText(402000)
    const sysText403000 = useSystemText(403000)
    
    const sysText500000 = useSystemText(500000)
    const sysText501000 = useSystemText(501000)
    const sysText502000 = useSystemText(502000)
    const sysText503000 = useSystemText(503000)
  
    const sysText600000 = useSystemText(600000)
    const sysText601000 = useSystemText(601000)
    const sysText602000 = useSystemText(602000)
   
    const sysText700000 = useSystemText(700000)
    const sysText701000 = useSystemText(701000)
    const sysText702000 = useSystemText(702000)
    const sysText703000 = useSystemText(703000)
    const sysText704000 = useSystemText(704000)
    const sysText705000 = useSystemText(705000)
    const sysText706000 = useSystemText(706000)
    const sysText707000 = useSystemText(707000)
    const sysText708000 = useSystemText(708000)
    const sysText709000 = useSystemText(709000)
    const sysText710000 = useSystemText(710000)
    const sysText711000 = useSystemText(711000)

    const sysText800000 = useSystemText(800000)
    const sysText801000 = useSystemText(801000)
    const sysText802000 = useSystemText(802000)
    const sysText803000 = useSystemText(803000)
    const sysText804000 = useSystemText(804000)
    const sysText805000 = useSystemText(805000)
    const sysText806000 = useSystemText(806000)
    const sysText807000 = useSystemText(807000)
    
    const sysText900000 = useSystemText(900000)
    
    const sysText1000000 = useSystemText(1000000)
    const sysText1001000 = useSystemText(1001000)
    const sysText1002000 = useSystemText(1002000)
    
    const menuData = [
        {
          id: "0",
          label: sysText100000,
          icon: 'pi pi-fw pi-home',
          link: "/home",
          command: () => navigate("/home")          
        },
        {
          id: "59",
          label: sysText800000,
          icon: 'pi pi-fw pi-chart-line',
          link: "/dashboard",
          visible: showMenuList.includes(59),
          items: [             
            { id:"60", label: sysText801000, link: "/dashboard/dailyResult", path: "dailyResult", visible: showMenuList.includes(60), className: location.pathname === "/dashboard/dailyResult" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/dailyResult")} },  
            { id:"61", label: sysText802000, link: "/dashboard/merchantMonitoring", path: "merchantMonitoring", visible: showMenuList.includes(61), className: location.pathname === "/dashboard/merchantMonitoring" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/merchantMonitoring")} },
            { id:"62", label: sysText804000, link: "/dashboard/agencyTransaction", path: "agencyTransaction", visible: showMenuList.includes(62), className: location.pathname === "/dashboard/agencyTransaction" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/agencyTransaction")} },
            { id:"63", label: sysText803000, link: "/dashboard/thirdPartyResult", path: "thirdPartyResult", visible: showMenuList.includes(63), className: location.pathname === "/dashboard/thirdPartyResult" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/thirdPartyResult")} },
            { id:"69", label: sysText805000, link: "/dashboard/mediatorDailyResult", path: "mediatorDailyResult", visible: showMenuList.includes(69), className: location.pathname === "/dashboard/mediatorDailyResult" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/mediatorDailyResult")} },
            { id:"70", label: sysText806000, link: "/dashboard/gatewayMonitoring", path: "gatewayMonitoring", visible: showMenuList.includes(70), className: location.pathname === "/dashboard/gatewayMonitoring" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/gatewayMonitoring")} },
            { id:"72", label: sysText807000, link: "/dashboard/mediatorFlowRecord", path: "mediatorFlowRecord", visible: showMenuList.includes(72), className: location.pathname === "/dashboard/mediatorFlowRecord" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/dashboard/mediatorFlowRecord")} }
            
          ],
        },
        {
          id: "38",
          label: sysText600000,
          icon: 'pi pi-fw pi-briefcase',
          link: "/merchantno",
          visible: showMenuList.includes(38),
          items: [             
            { id:"39", label: sysText601000, link: "/merchantno/merchantnoList", path: "merchantnoList", visible: showMenuList.includes(39), className: location.pathname === "/merchantno/merchantnoList" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/merchantno/merchantnoList")} },                
            { id:"71", label: sysText602000, link: "/merchantno/complaintList", path: "complaintList", visible: showMenuList.includes(71), className: location.pathname === "/merchantno/complaintList" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/merchantno/complaintList")} },                
          ],
        }, 
        { 
          id: "21",
          label: sysText200000,
          icon: 'pi pi-fw pi-file',
          link: "/orderManage",
          visible: showMenuList.includes(21),
          items: [             
            // { id: "1", label: "订单记录", link: "/orderManage/record", path: "record", visible: showMenuList.includes(1), className: location.pathname === "/orderManage/record" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/record")}},
            { id: "40", label: sysText201000, link: "/orderManage/record-Ncc", path: "record-Ncc", visible: showMenuList.includes(40), className: location.pathname === "/orderManage/record-Ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/record-Ncc")}},
            // { id: "57", label: "冻结订單", link: "/orderManage/freezeOrder", path: "freezeOrder", visible: showMenuList.includes(57), className: location.pathname === "/other/freezeOrder" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/freezeOrder")} },
            { id: "58", label: sysText202000, link: "/orderManage/freezeOrder-Ncc", path: "freezeOrder-Ncc", visible: showMenuList.includes(58), className: location.pathname === "/other/freezeOrder-Ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/freezeOrder-Ncc")} },
            // { id: "3", label: "订单修改", link: "/orderManage/recordEdit", path: "recordEdit", visible: showMenuList.includes(3), className: location.pathname === "/orderManage/recordEdit" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/recordEdit")}, disabled: true },
            { id: "2", label: sysText203000, link: "/orderManage/flowRecord", path: "flowRecord", visible: showMenuList.includes(2), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/flowRecord")}},
            { id: "31", label: sysText204000, link: "/orderManage/addFlowRecord", path: "addFlowRecord", visible: showMenuList.includes(31), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/addFlowRecord")}},
            // { id: "6", label: "新增订单", link: "/orderManage/recordAdd", path: "recordAdd", visible: showMenuList.includes(6), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/recordAdd")}},
            { id: "41", label: sysText205000, link: "/orderManage/recordAdd-Ncc", path: "recordAdd-Ncc", visible: showMenuList.includes(41), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/recordAdd-Ncc")}},
            // { id: "37", label: "冻结记录", link: "/orderManage/freezeRecord", path: "freezeRecord", visible: showMenuList.includes(37), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/freezeRecord")}},
            { id: "53", label: sysText206000, link: "/orderManage/orderNotify", path: "orderNotify", visible: showMenuList.includes(53), className: location.pathname === "/other/orderNotify" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/orderManage/orderNotify")} },
            { id: "18", label: sysText207000, link: "https://shutokou.cc/", path: "https://shutokou.cc/", visible: showMenuList.includes(18), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {window.open("https://shutokou.cc/", '_blank')}},
            { id: "19", label: sysText208000, link: "https://expressdeles.com/", path: "https://expressdeles.com/", visible: showMenuList.includes(19), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {window.open("https://expressdeles.com/", '_blank')}},  
          ],
        },
        {
          id: "22",
          label: sysText300000,
          icon: 'pi pi-fw pi-shopping-cart',
          link: "/purchaseManage",
          visible: showMenuList.includes(22),
          items: [             
            // { id:"7", label: "采购纪录", link: "/purchaseManage/purchaseRecord", path: "purchaseRecord", visible: showMenuList.includes(7), className: location.pathname === "/purchaseManage/purchaseRecord" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseRecord")} },
            { id:"47", label: sysText306000, link: "/purchaseManage/purchaseRecord-Ncc", path: "purchaseRecord-Ncc", visible: showMenuList.includes(47), className: location.pathname === "/purchaseManage/purchaseRecord-Ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseRecord-Ncc")} },
            // { id:"8", label: "采购单配对", link: "/purchaseManage/purchaseMate", path: "purchaseMate", visible: showMenuList.includes(8), className: location.pathname === "/purchaseManage/purchaseMate" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseMate")} },
            { id:"48", label: sysText305000, link: "/purchaseManage/purchaseMate-Ncc", path: "purchaseMate-Ncc", visible: showMenuList.includes(48), className: location.pathname === "/purchaseManage/purchaseMate-Ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseMate-Ncc")} },
            { id:"67", label: sysText309000, link: "/purchaseManage/purchaseMate-ThirdParty", path: "purchaseMate-ThirdParty", visible: showMenuList.includes(67), className: location.pathname === "/purchaseManage/purchaseMate-ThirdParty" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseMate-ThirdParty")} },
            { id:"55", label: sysText301000, link: "/purchaseManage/purchaseNotify", path: "purchaseNotify", visible: showMenuList.includes(55), className: location.pathname === "/other/purchaseNotify" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseNotify")} },
            { id:"46", label: sysText302000, link: "/purchaseManage/purchaseGrab", path: "purchaseGrab", visible: showMenuList.includes(46), className: location.pathname === "/purchaseManage/purchaseGrab" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseGrab")} },
            // { id:"10", label: "新增采购单", link: "/purchaseManage/purchaseAdd", path: "purchaseAdd", visible: showMenuList.includes(10), className: location.pathname === "/purchaseManage/purchaseAdd" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseAdd")} },
            { id:"49", label: sysText303000, link: "/purchaseManage/purchaseAdd-Ncc", path: "purchaseAdd-Ncc", visible: showMenuList.includes(49), className: location.pathname === "/purchaseManage/purchaseAdd-Ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseAdd-Ncc")} },
            { id:"66", label: sysText308000, link: "/purchaseManage/purchaseAdd-thirdParty", path: "purchaseAdd-thirdParty", visible: showMenuList.includes(66), className: location.pathname === "/purchaseManage/purchaseAdd-thirdParty" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseAdd-thirdParty")} },
            { id:"30", label: sysText304000, link: "/purchaseManage/purchaseReversal", path: "/purchaseManage/purchaseReversal", visible: showMenuList.includes(30), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/purchaseManage/purchaseReversal")}},
            { id:"20", label: sysText307000, link: "https://answer2.shop/", path: "https://answer2.shop/", visible: showMenuList.includes(20), className: location.pathname === "" ? "menuItem-action" : "menuItem", command: ()=> {window.open("https://answer2.shop/", '_blank')}},

                    
          ],
        },
        {
          id: "23",
          label: sysText400000,
          icon: 'pi pi-fw pi-user',
          link: "/accountManage",
          visible: showMenuList.includes(23),
          items: [             
            { id:"11", label: sysText401000, link: "/accountManage/systemInformation", path: "systemInformation", visible: showMenuList.includes(11), className: location.pathname === "/accountManage/systemInformation" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/accountManage/systemInformation")} },
            { id:"12", label: sysText402000, link: "/accountManage/userManage", path: "userManage", visible: showMenuList.includes(12), className: location.pathname === "/accountManage/userManage" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/accountManage/userManage")} },
            { id:"36", label: sysText403000, link: "/accountManage/gatewaySetting", path: "gatewaySetting", visible: showMenuList.includes(36), className: location.pathname === "/accountManage/gatewaySetting" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/accountManage/gatewaySetting")} },
          ],
        },
        {
          id: "24",
          label: sysText500000,
          icon: 'pi pi-fw pi-wallet',
          link: "/share",
          visible: showMenuList.includes(24),
          items: [             
            { id:"13", label: sysText501000, link: "/share/shareRecord", path: "shareRecord", visible: showMenuList.includes(13), className: location.pathname === "/share/shareRecord" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/share/shareRecord")} },
            // { label: "分润变动记录", link: "/share/edit", path: "edit", visible: showMenuList.includes(14), className: location.pathname === "/share/edit" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/share/edit")}, disabled: true },
            // { id:"14", label: sysText502000, link: "/share/shareEdit", path: "shareEdit", visible: showMenuList.includes(14), className: location.pathname === "/share/shareEdit" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/share/shareEdit")} },     
            { id:"65", label: sysText503000, link: "/share/shareEditGateway", path: "shareEditGateway", visible: showMenuList.includes(65), className: location.pathname === "/share/shareEditGateway" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/share/shareEditGateway")} }
          ],
        },               
        {
          id: "26",
          label: sysText1000000,
          icon: 'pi pi-fw pi-at',
          link: "/wechatHelper",
          visible: showMenuList.includes(26),
          items: [             
            { id:"27", label: sysText1002000, link: "/wechatHelper/startUp", path: "startUp", visible: showMenuList.includes(27), className: location.pathname === "/wechatHelper/startUp" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/wechatHelper/startUp")} },
            { id:"29", label: sysText1001000, link: "/wechatHelper/statusManage", path: "statusManage", visible: showMenuList.includes(29), className: location.pathname === "/wechatHelper/statusManage" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/wechatHelper/statusManage")} }
          ],
        },
        {
          id: "25",
          label: sysText700000,
          icon: 'pi pi-fw pi-wrench',
          link: "/other",
          visible: showMenuList.includes(25),
          items: [             
            // { id:"15", label: "货款卡设定", link: "/other/cardSetting", path: "cardSetting", visible: showMenuList.includes(15), className: location.pathname === "/other/cardSetting" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardSetting")} },
            { id:"44", label: sysText701000, link: "/other/cardSetting-cc", path: "cardSetting-cc", visible: showMenuList.includes(44), className: location.pathname === "/other/cardSetting-cc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardSetting-cc")} },
            { id:"42", label: sysText702000, link: "/other/cardSetting-ncc", path: "cardSetting-ncc", visible: showMenuList.includes(42), className: location.pathname === "/other/cardSetting-ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardSetting-ncc")} },
            // { id:"28", label: "货款卡审核", link: "/other/cardCheck", path: "cardCheck", visible: showMenuList.includes(28), className: location.pathname === "/other/cardCheck" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardCheck")} },
            { id:"45", label: sysText707000, link: "/other/cardCheck-cc", path: "cardCheck-cc", visible: showMenuList.includes(45), className: location.pathname === "/other/cardCheck-cc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardCheck-cc")} },
            { id:"43", label: sysText708000, link: "/other/cardCheck-ncc", path: "cardCheck-ncc", visible: showMenuList.includes(43), className: location.pathname === "/other/cardCheck-ncc" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/cardCheck-ncc")} },
            { id:"16", label: sysText703000, link: "/other/creditManage", path: "creditManage", visible: showMenuList.includes(16), className: location.pathname === "/other/creditManage" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/creditManage")} },
            // { id:"32", label: "银行关键字", link: "/other/bankKeyword", path: "bankKeyword", visible: showMenuList.includes(32), className: location.pathname === "/other/bankKeyword" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/bankKeyword")} },
            { id:"33", label: sysText704000, link: "/other/reportDownload", path: "reportDownload", visible: showMenuList.includes(33), className: location.pathname === "/other/reportDownload" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/reportDownload")} },
            // { id:"34", label: "资讯看板", link: "/other/informationBoard", path: "informationBoard", visible: showMenuList.includes(34), className: location.pathname === "/other/informationBoard" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/informationBoard")} },
            { id:"35", label: sysText709000, link: "/other/menuSetting", path: "menuSetting", visible: showMenuList.includes(35), className: location.pathname === "/other/menuSetting" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/menuSetting")} },
            { id:"50", label: sysText705000, link: "/other/thirdParty", path: "thirdParty", visible: showMenuList.includes(50), className: location.pathname === "/other/thirdParty" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/thirdParty")} },
            { id:"56", label: sysText706000, link: "/other/riskControl", path: "riskControl", visible: showMenuList.includes(56), className: location.pathname === "/other/riskControl" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/riskControl")} },
            { id:"64", label: sysText710000, link: "/other/systemTextSetting", path: "systemTextSetting", visible: showMenuList.includes(64), className: location.pathname === "/other/systemTextSetting" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/systemTextSetting")} },
            { id:"68", label: sysText711000, link: "/other/mediatorSetting", path: "mediatorSetting", visible: showMenuList.includes(68), className: location.pathname === "/other/mediatorSetting" ? "menuItem-action" : "menuItem", command: ()=> {navigate("/other/mediatorSetting")} },
          
          ],
        },
        
        {
          id: "0",
          label: sysText900000,
          icon: 'pi pi-fw pi-refresh',    
          link: "/clearCache",      
          command: ()=> {navigate("/clearCache")}
        }
      ]

    const value = {
        menuData,
        showMenu,
        setShowMenu,
        showMenuList,
        setShowMenuList
    };

    return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
};

export default MenuContext
