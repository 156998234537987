import React, { useContext, useEffect } from 'react';
import Menu from '../layouts/menu';
import MenuContext from '../context/menuContext';
import { Outlet } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb';
import ToastContext, { ToastProvider } from '../context/ToastContext';
import LoadingContext from '../context/loadingContext';

const Main = () => {

	const {showMenu} = useContext(MenuContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)

  useEffect(() => {
    setIsRefresh(prev => !prev)
    console.log("session: ", sessionStorage.getItem("systemText"));
    console.log("isRefresh: ", isRefresh);
  }, [sessionStorage.getItem("systemText")])
     
  return (
      
      <div className='flex h-full'>
        <Menu></Menu>
        <div className='mainWrap w-full min-2xl:p-4 max-2xl:px-0 max-2xl:py-2 h-full overflow-auto'>
            <div className='max-2xl:hidden'>
              <Breadcrumb  />
            </div>          
            <div className='h-[calc(100%-48px)]'>
              <Outlet/>
            </div>                           
            
        </div>
      </div>
      
  );
};

export default Main;