import React, {useContext, useState} from 'react';
import  Dialog from '../../dialog'
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_38_update_user_hierarchy, web_56_edit_percen } from '../../../api/api';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch'
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const UserManageEditUser = (props) => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {editUserData, setShowEditUser} = props

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText402002 = useSystemText(402002)
  const sysText402003 = useSystemText(402003)
  const sysText402004 = useSystemText(402004)
  const sysText402012 = useSystemText(402012)
  const sysText402013 = useSystemText(402013)
  const sysText402033 = useSystemText(402033)
  
  const formik = useFormik({
    initialValues: {
      hierarchy: editUserData.層級,
      batchEdit: false
    },
    validate: (data) => {
        let errors = {};
        const hierarchyRegExp = /^(?:[1-9]?[0-9]|99)$/       

        if (!hierarchyRegExp.test(data.hierarchy)) {								
          errors.hierarchy = '必须是0 ~ 99';
        }

        return errors;
    },
    onSubmit: (data) => {     

      const confirmHandler = async() => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          update_uuid: editUserData.uuid,
          hierarchy: data.hierarchy,
          botton_type: data.batchEdit ? "1" : "0"
        }
               
        try {
          const { listResponse, status, statusText} = await web_38_update_user_hierarchy(filterData);          
                    
    
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            setShowEditUser(false)          
            showToast("success", "" + status, `${sysText402033}: \n${editUserData.用戶名稱}` )
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }

      confirmHandler()
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <Dialog>
      <div className='mx-auto w-[35vw] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          <div className='flex flex-row items-center p-6 border-b border-gray-700'>
            <h2 className='text-lg'>{sysText402012}</h2>
            <div className='ml-auto'>
              <HelpIcon tooltipText={useHelpText(3)}/>
            </div>
          </div>           
          <div className='leading-9 p-4 text-sm'>
            <p>{sysText402002}：{editUserData.帳號}</p>
            <p>{sysText402003}：{editUserData.用戶名稱}</p>
            <p>{sysText402004}</p>
            <input type="number" name="hierarchy" value={formik.values.hierarchy} onChange={formik.handleChange} className='w-full'></input>
            {getFormErrorMessage('hierarchy')}
            <div className='flex items-center space-x-4'>
              <label className=''>{sysText402013}</label>
              <InputSwitch name="batchEdit" checked={formik.values.batchEdit} value={formik.values.batchEdit} onChange={formik.handleChange} className="p-inputswitch" />
            </div>
            
          </div>  
          
          <div className='flex flex-auto w-full justify-around items-center border-t border-gray-700 p-2'>
            <Button className='flex justify-center w-24' label={sysText10003} onClick={() => {setShowEditUser(false)}} severity="secondary" raised></Button>
            <Button type='submit' className='flex justify-center w-24' label={sysText10002} severity="info" raised></Button>
          </div> 
        </form>
        
      </div>
    </Dialog>
  );
};

export default UserManageEditUser;