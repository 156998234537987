import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { web_116_select_gateway_share, web_52_get_all_uuid, web_56_ncc_edit_percen, web_81_get_gateway } from '../../api/api';
import { InputNumber } from 'primereact/inputnumber';
import useSystemText from '../../helper/useSystemText';
import { Dropdown } from 'primereact/dropdown';
import HelpIcon from '../../components/UI/helpIcon';
import useHelpText from '../../helper/useHelpText';
import useTableSize from '../../helper/useTableSize';

const ShareEditGateway = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)  
  const location = useLocation()

  const [uuidOption, setUuidOption] = useState([])
  const [gatewayNameOption, setGatewayNameOption] = useState([])
  const [selectedValues, setSelectedValues] = useState({ uuid: '', gatewayName: '' });
  const [upperData, setUpperData] = useState([])
  const [downData, setDownData] = useState([])
  const tableScrollY = useTableSize(50, 40)
  
  const sysText502001 = useSystemText(502001)
  const sysText502002 = useSystemText(502002)
  const sysText502003 = useSystemText(502003)
  const sysText502004 = useSystemText(502004)
  const sysText502005 = useSystemText(502005)  
  const sysText502007 = useSystemText(502007)
  const sysText502012 = useSystemText(502012) 
  const sysText503001 = useSystemText(503001)
  const sysText503002 = useSystemText(503002)
  const sysText503003 = useSystemText(503003)
  const sysText503004 = useSystemText(503004)

  const helpText17 = useHelpText(17)

  const PercenColumn = (item) => {
    
    const onBlurHandler = async(e) => {
      
      if(item.percen != e.target.value) {
        setIsLoading(true)   
        const filterData = {  
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          gateway_id: selectedValues.gatewayName.id,
          percen: e.target.value,
          percen_uuid: selectedValues.uuid

        };
        try {
          const { listResponse, status, statusText } = await web_56_ncc_edit_percen(filterData);             
          
          if (status === 200) {         
            setIsRefresh(prev => !prev)            
            showToast("success", "" + status, `${sysText503004}: \n${filterData.percen_uuid}-${selectedValues.gatewayName.display_name}-${filterData.percen}` )    
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }      
    }    

    return <InputNumber className='text-red-100' value={item.percen} onBlur={(e) => onBlurHandler(e)} minFractionDigits={2} maxFractionDigits={2} />
  }

  const ActiveColumn = (item) => {               
       
    const editPercentHandler = () => {
      setSelectedValues(prev => ({uuid: item.uuid, gatewayName: prev.gatewayName}))
    }   

    return (      
      <div className='activeBtn flex flex-col items-center justify-around'>          
        <Button label={sysText503001} severity="info" raised onClick={editPercentHandler} />        
      </div>
    )
  }  

  useEffect(() => {        

    const fetchUuidData = async() => {    
      setIsLoading(true)   
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),       
      };
      try {
        const { listResponse, status, statusText } = await web_52_get_all_uuid(filterData);        
        
        if (status === 200) {                     
          setUuidOption(listResponse.map(item => ({name: `${item.uuid} - ${item.用戶姓名}`, value: item.uuid})))            
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const fetchGatewayNameData = async() => {    
      setIsLoading(true)   
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        merchantno: [],
        select_state: "",
        select_type: ""             
      };
      try {
        const { listResponse, status, statusText } = await web_81_get_gateway(filterData);        
        
        if (status === 200) {                     
          setGatewayNameOption(listResponse)            
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchUuidData()
    fetchGatewayNameData()
  }, [isRefresh]); 

  useEffect(() => {    
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select_uuid: selectedValues.uuid,
      select_gateway: selectedValues.gatewayName.display_name
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_116_select_gateway_share(filterData);        
        
        if (status === 200) {                     
          setUpperData(listResponse.select_uuid_data)
          setDownData(listResponse.other_data)        
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    if(selectedValues.uuid && selectedValues.gatewayName)  {
      fetchData(filterData)
    }
    
  }, [selectedValues, isRefresh]); 

  return (
    <div className="min-2xl:p-4 max-2xl:py-2 recordWrap tableWrap">
      <div className='flex flex-wrap mb-2'>        
        <div className='flex flex-row flex-wrap items-center min-2xl:ml-auto max-2xl:w-[100%] space-x-4'>      
          <HelpIcon tooltipText={helpText17}/>                    
          <Dropdown className='custom-dropdown' name="selectedValues" value={selectedValues.uuid} options={uuidOption} optionLabel="name" optionValue="value" filter onChange={(e) => {setSelectedValues(prev => ({uuid: e.value, gatewayName: prev.gatewayName}))}} placeholder={sysText503002}></Dropdown>          
          <Dropdown className='custom-dropdown' name="selectedValues" value={selectedValues.gatewayName} options={gatewayNameOption} optionLabel="display_name" filter onChange={(e) => {setSelectedValues(prev => ({uuid: prev.uuid, gatewayName: e.value}))}} placeholder={sysText503003}></Dropdown>          
        </div>                        
      </div>

      <div className='flex flex-col'>           
        <DataTable value={upperData} scrollable scrollHeight='200px'>               
          <Column style={{ minWidth: "100px" }} field="uuid" header={sysText502001}></Column>
          <Column style={{ minWidth: "100px" }} field="用戶名" header={sysText502002}></Column>
          <Column style={{ minWidth: "100px" }} field="最上層" header={sysText502003}></Column>
          <Column style={{ minWidth: "100px" }} field="上層" header={sysText502004}></Column>
          <Column style={{ minWidth: "100px", maxWidth: "220px" }} field="percen" header={sysText502005} body={PercenColumn}></Column>
        </DataTable>         
           
        <div>
          <div className='flex justify-center items-center text-blue-400 font-bold rounded-md w-10'>{sysText502012}</div>
                  
            <DataTable value={downData} scrollable scrollHeight={tableScrollY}>               
              <Column style={{ minWidth: "100px" }} field="uuid" header={sysText502001}></Column>
              <Column style={{ minWidth: "100px" }} field="用戶名" header={sysText502002}></Column>
              <Column style={{ minWidth: "100px" }} field="最上層" header={sysText502003}></Column>
              <Column style={{ minWidth: "100px" }} field="上層" header={sysText502004}></Column>
              <Column style={{ minWidth: "220px", maxWidth: "220px" }} field="percen" header={sysText502005} body={PercenColumn}></Column>
              <Column style={{ minWidth: "120px" }} field="active" header={sysText502007} body={ActiveColumn} headerClassName="centered-header"></Column>               
            </DataTable>
        </div>
      </div>
    </div>
  );
}

export default ShareEditGateway


