import React, { useState, useEffect, useContext } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
// import ShareRecordCard from '../../../components/share/shareRecord/shareRecordCard';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import ShareRecordWithdraw from '../../../components/share/shareRecord/shareRecordWithdraw';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
// import WithdrawRecord from './withdrawRecord';
import { ToastContext } from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import FilterInput from '../../components/UI/filterInput';
import { web_138 } from '../../api/api';
import PaginatorUI from '../../components/UI/paginatorUI';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const MediaitorFlowRecord = () => {

  const dateToString = (start_date, end_date) => {      
     
    const startDateString = new Date(start_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const endDateString = new Date(end_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const dateString = `${startDateString} ~ ${endDateString}`

    return dateString
  } 

  const [flowRecordData, setFlowRecordData] = useState([])
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [filterNumber, setFilterNumber] = useState("")
  const [filterDate, setFilterDate] = useState([])
  const [displayDate, setDisplayDate] = useState(dateToString(-2209017600000, new Date().getTime()))
  const [filterStatus, setFilterStatus] = useState("")
  const [tabMenu, setTabMenu] = useState('order')
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10); 
  const [totalCount, setTotalCount] = useState(0)
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const tableScrollY = useTableSize(55, 45)
  const { showToast, isRefresh } = useContext(ToastContext)
  const location = useLocation()
  const [searchField, setSearchField] = useState("訂單編號")

  const sysText807001 = useSystemText(807001)
  const sysText807002 = useSystemText(807002)
  const sysText807003 = useSystemText(807003)
  const sysText807004 = useSystemText(807004)
  const sysText807005 = useSystemText(807005)
  const sysText807006 = useSystemText(807006)
  const sysText807007 = useSystemText(807007)
  const sysText807008 = useSystemText(807008)
  const sysText807009 = useSystemText(807009)
  const sysText807010 = useSystemText(807010)
  const sysText807011 = useSystemText(807011)
  const sysText807012 = useSystemText(807012)

  
  const searchFieldOption = [
    {name: sysText807003, value: "訂單編號"},
    {name: sysText807001, value: "商戶名"},
    {name: sysText807004, value: "訂單帳號"},
    {name: sysText807002, value: "中間人"}
  ]

  // const items = [
  //   {label: `${sysText203017}`, icon: '', command: () => {setTabMenu('order')}},
  //   {label: `${sysText203018}`, icon: '',  command: () => {setTabMenu('withdraw')}}    
  // ];

  const statusOption = [
    { name: `${sysText807012}`, value: ""},
    { name: `${sysText807011}`, value: '1' },   
  ];

  const OperationColumn = (item) => {
    switch(item.操作類型) {
      case "1":
        return sysText807011             
    }  
  }   

  const tableColumns = [
    {
      header: sysText807001,
      field: "商戶編號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText807002,
      field: "中間人",
      style: {minWidth: "120px"},
    },
    {
      header: sysText807003,
      field: "訂單編號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText807004,
      field: "員工帳號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText807005,
      field: "操作類型",
      style: {minWidth: "120px"},
      body: OperationColumn
    },
    {
      header: sysText807006,
      field: "交易管道",
      style: {minWidth: "120px"},
    },   
    {
      header: sysText807007,
      field: "通道名稱",
      style: {minWidth: "120px"},
    },  
    {
      header: sysText807008,
      field: "訂單金額",
      style: {minWidth: "120px"},
    },       
    {
      header: sysText807009,
      field: "中間人費用",
      style: {minWidth: "120px"},
    },
    {
      header: sysText807010,
      field: "變動日期",
      style: {minWidth: "120px"},
    },
    // {
    //   header: sysText203016,
    //   field: "備註",
    //   style: {minWidth: "120px"},
    // },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState([])

useEffect(() => {
  setVisibleColumns(filterColumnForUuid)
}, [isRefresh])

  useEffect(() => {
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),      
      serial: filterNumber,     
      start_date: filterDate?.[0] ? filterDate[0] : -2209017600000,
      end_date: filterDate?.[1] ? filterDate[1] : new Date().getTime(),
      operatetype: filterStatus,
      page: page + 1,
      limit: 10,
      field: searchField
    };    

    const fetchData = async () => {   
      setIsLoading(true)    
      try {        
        const { listResponse, status, statusText } = await web_138(filterData); 

        if (status === 200) {                     
          setFlowRecordData(listResponse.data)
          setTotalCount(listResponse.count)   
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()  
    if(filterDate[0] && filterDate[1]) {
      setDisplayDate(dateToString(filterDate[0], filterDate[1]))
    } else {
      setDisplayDate(dateToString(-2209017600000, new Date().getTime()))
    }
  }, [isRefresh, filterDate, filterNumber, filterStatus, page])




  return (
    <div className="shareRecord px-2">       
      <div className='flex w-full'>
        <div className='flex flex-col w-full my-2 max-2xl:w-[100%]'>              
          <div className='flex flex-row flex-wrap max-2xl:justify-center w-full'>
            
            <div className='flex min-2xl:ml-auto max-2xl:w-full'>
              <FilterInput setFilterNumber={setFilterNumber} setFilterDate={setFilterDate} setFirst={setFirst} setPage={setPage} placeholder={""} searchField={searchField} setSearchField={setSearchField} searchFieldOption={searchFieldOption}>
                <MultiSelect key={isRefresh} value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect max-2xl:hidden" display='chip'/>
              </FilterInput>
            </div>
          </div>
          <div className='tableWrap'>
            
            <div className='flex flex-row py-2'>             
              <div className="max-md:hidden ml-auto">
                <Dropdown className='custom-dropdown' placeholder="状态" value={filterStatus} onChange={(e) => {setFilterStatus(e.target.value)}} options={statusOption} optionLabel='name'/>                    
              </div>     
            </div>                        

            <div className=''>
              <DataTable value={flowRecordData} scrollable scrollHeight={tableScrollY}>                   
                {visibleColumns.map((row,index) =>             
                  <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
                )}
              </DataTable>
            </div>                   

            <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
          </div>
                        
        </div>       
      </div>     
      
    </div>
  );
};

export default MediaitorFlowRecord;