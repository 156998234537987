import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import FilterInput from '../../components/UI/filterInput';
// import Card from '../../components/other/cardSetting/card';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { web_23_ncc_get_gateway, web_39_ncc_find_user_ncc_set, web_57_ncc_set_card } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import DeleteCard from '../../components/other/cardSettingNcc/deleteCard';
import { SplitButton } from 'primereact/splitbutton';
import AddCardNcc from '../../components/other/cardSettingNcc/addCardNcc';
import EditCardNcc from '../../components/other/cardSettingNcc/editCardNcc';
import { imgOnError } from '../../helper/imgOnError';
import BatchEditNcc from '../../components/other/cardSettingNcc/batchEditNcc';
import HelpIcon from '../../components/UI/helpIcon';
import useHelpText from '../../helper/useHelpText';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const CardSettingCc = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const [showAddCard, setShowAddCard] = useState(false)
  const [showEditCard, setShowEditCard] = useState(false)
  const [editCardData, setEditCardData] = useState({})
  const [showDeleteCard, setShowDeleteCard] = useState(false)
  const [deleteCardData, setDeleteCardData] = useState({})
  const [cardSettingData, setCardSettingData] = useState([])
  const [filterName, setFilterName] = useState("")
  const { showToast, isRefresh, setIsRefresh } = useContext(ToastContext)
  const location = useLocation()
  const [info, setInfo] = useState(null);
  const [gatewayList, setGatewayList] = useState(null)
  const [pictureData, setPictureData] = useState(null)
  const [showBatchEdit, setShowBatchEdit] = useState(false)
  const helpTextPicture = useHelpText(0)
  const tableScrollY = useTableSize(60, 60)

  const sysText10021 = useSystemText(10021)
  const sysText10034 = useSystemText(10034)
  const sysText10035 = useSystemText(10035)
  const sysText10036 = useSystemText(10036)
  const sysText10037 = useSystemText(10037)

  const sysText701001 = useSystemText(701001)
  const sysText701002 = useSystemText(701002)
  const sysText701003 = useSystemText(701003)
  const sysText701004 = useSystemText(701004)
  const sysText701005 = useSystemText(701005)  
  const sysText701006 = useSystemText(701006)
  const sysText701007 = useSystemText(701007)
  const sysText701008 = useSystemText(701008)
  const sysText701009 = useSystemText(701009)
  const sysText701010 = useSystemText(701010)  
  const sysText701011 = useSystemText(701011)
  const sysText701012 = useSystemText(701012)
  const sysText701013 = useSystemText(701013)
  const sysText701014 = useSystemText(701014)
  const sysText701015 = useSystemText(701015)
  const sysText701016 = useSystemText(701016)
  const sysText701033 = useSystemText(701033)
  const sysText701034 = useSystemText(701034)
  const sysText701025 = useSystemText(701025)
  const sysText701026 = useSystemText(701026)
  const sysText701035 = useSystemText(701035)
  const sysText701036 = useSystemText(701036)

  const InfoColumn = (rowData) => {
    return <Button label={sysText701016} severity='info' text raised onClick={(e) => { 
      try{
        const data = JSON.parse(rowData.資訊)
        setInfo(data)
      }catch (e) {
        setInfo(null)
        showToast("error", "资料格式错误无法显示")
      }
    }}></Button>
  }
  const InfoDialog = ({ info, setInfo }) => {
    if (info === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setInfo(null)}
        header={sysText701016}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText701016}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {Object.keys(info).map((key) => 
              <p key={key}>{`${key}: ${info[key]}`}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const fetchGateway = async(rowData) => {
    setIsLoading(true) 
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id_list: rowData?.通道,       
      };         
            
      try {
        const { listResponse, status, statusText } = await web_23_ncc_get_gateway(filterData);

        

        if (status === 200) {
          setGatewayList(listResponse)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
  }

  const GatewayColumn = (rowData) => {
    return <Button label={sysText701015} severity='info' text raised onClick={() => {fetchGateway(rowData)}}></Button>
  }

  const GatewayDialog = ({ gatewayList, setGatewayList }) => {
    if (gatewayList === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setGatewayList(null)}
        header={sysText701015}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText701015}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            {gatewayList && gatewayList.map((item) => 
            <p key={item}>
              {item.顯示名稱}
            </p>
          )}
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setGatewayList(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const PictureColumn = (rowData) => {
    return <Button icon="pi pi-qrcode" severity="info" text raised onClick={() => {setPictureData(rowData)}}/>
  }

  const PictureDialog = () => {
    if (pictureData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setPictureData(null)}
        header={sysText701036}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='flex flex-row border-b border-gray-700 p-4 text-lg'>
            <h2>{sysText701036}</h2>
            <div className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></div>
          </div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            <div className='p-2 flex justify-center items-center'>           
              {pictureData.圖片位置 ? <img className='max-w-[180px] max-h-[180px]' onError={({currentTarget}) => imgOnError(currentTarget)} src={pictureData.圖片位置}/>
              :  
              <div className='flex justify-center items-center w-[180px] h-[180px] text-gray-500 text-sm bg-customColor-bgLightGray'>FAILED</div>                    
              }
            </div>  
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setPictureData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const dailyCashColumn = (item) => {
    return <div>{item.當前收款額 + "/" + item.日收款額上限}</div>
  }

  const singleAmountColumn = (item) => {
    return <div>{item.單筆下限 + "/" + item.單筆上限}</div>
  }

  const ActiveColumn = (item) => {

    const statusBtnCheck = () => {
      let label = ''
      let severity = ''
      switch (item.狀態) {
        case 0:
          label = sysText10034
          severity = "help"
          break
        case 1:
          label = sysText10035
          severity = "success"
          break
        case 2:
          label = sysText10037
          severity = "warning"
          break
        case 3:
          label = sysText10036
          severity = "danger"
          break
        case 9:
          label = sysText10021
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return { label, severity }
    }

    const { label, severity } = statusBtnCheck()

    const statusHandler = async() => {     
      setIsLoading(true) 
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id: item.id,
        paymentamountdaylimit: item.日收款額上限,
        single_payment_upper_limit: item.單筆上限,
        single_payment_lower_limit: item.單筆下限,
        balance: item.餘額,
        info: item.資訊,        
        old_paymentamountdaylimit: item.日收款額上限,
        old_single_payment_upper_limit: item.單筆上限,
        old_single_payment_lower_limit: item.單筆下限,
        old_balance: item.餘額,
        old_info: item.資訊,
        state: item.狀態 == 0 ? "1" : "0"        
      };         
            
      try {
        const { listResponse, status, statusText } = await web_57_ncc_set_card(filterData);

        

        if (status === 200) {
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }

    const showEditCardHandler = () => {
      setShowEditCard(true)
      setEditCardData(item)
    }

    const showDeleteCardHandler = () => {
      setDeleteCardData(item)
      setShowDeleteCard(true)
    }   

    const menuItems1_2 = [
      {
        label: item.狀態 === 1 ? sysText701025 : sysText701026,
        command: () => {
          if(item.狀態 === 0 || item.狀態 === 1){
            statusHandler()
          }          
        }
      },
      {
        label: sysText701033,
        command: showEditCardHandler,
      }      
    ];
    
    const menuItems3_9 = [
      {
        label: item.狀態 === 1 ? sysText701025 : sysText701026,
        command: statusHandler
      },
      {
        label: sysText701033,
        command: showEditCardHandler,
      },
      {
        label: sysText701034,
        command: showDeleteCardHandler,
      },
    ];
    
    const menuItems8 = [
      {
        label: item.狀態 === 1 ? sysText701025 : sysText701026,
        command: statusHandler
      },    
      {
        label: sysText701034,
        command: showDeleteCardHandler,
      },
    ]; 
    
    return (
      
      <div className='activeBtn flex flex-col items-center justify-around p-0'>
        {(permissions === 1 || permissions === 2) &&          
          <SplitButton label={label} model={menuItems1_2} severity={severity} />
        }

        {(permissions === 3 || permissions === 9) &&          
          <SplitButton label={label} model={menuItems3_9} severity={severity} />
        }

        {(permissions === 8) &&          
          <SplitButton label={label} model={menuItems8} severity={severity} />
        }        
      </div>      
    ) 
    
  }  

  const tableColumns = [
    {
      header: sysText701001,
      field: "uuid",
      style: {minWidth: "80px"},
      sortable: true
    },
    {
      header: sysText701002,
      field: "用戶名稱",
      style: {minWidth: "120px"},
      sortable: true
    },
    {
      header: sysText701003,
      field: "顯示名稱",
      style: {minWidth: "120px"},
      sortable: true
    },
    {
      header: sysText701004,
      field: "通道",
      style: {minWidth: "120px"},
      body: GatewayColumn,
    },
    {
      header: sysText701005,
      field: "資訊",
      style: {minWidth: "120px"},
      body: InfoColumn
    },
    {
      header: sysText701006,
      field: "收款人姓名",
      style: {minWidth: "100px"},
    },
    {
      header: sysText701007,
      field: "日收款額",
      style: {minWidth: "120px"},
      body: dailyCashColumn
    },
    {
      header: sysText701008,
      field: "單筆限額",
      style: {minWidth: "80px"},
      body: singleAmountColumn
    },
    {
      header: sysText701009,
      field: "餘額",
      style: {minWidth: "120px"},
      sortable: true
    },
    {
      header: sysText701010,
      field: "當前信用額度",
      style: {minWidth: "120px"},
      sortable: true
    },
    {
      header: sysText701011,
      field: "二維碼圖片",
      style: {minWidth: "120px"},
      body: PictureColumn
    },
    {
      header: sysText701012,
      field: "active",
      style: {minWidth: "140px"},
      sortable: true,
      headerClassName: "centered-header",
      body: ActiveColumn
    },


  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState([])

useEffect(() => {
  setVisibleColumns(filterColumnForUuid)
}, [isRefresh])

  useEffect(() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterName,
      page: 1,
      limit: 1000000,
      card: "1"
    };

    const fetchData = async () => {
      
      try {
        const { listResponse, status, statusText } = await web_39_ncc_find_user_ncc_set(filterData);
        
        if (status === 200) {         
          setCardSettingData(listResponse.data)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterName, isRefresh]);
 
  return (
    <div className="cardSettingWrap flex flex-col">
      <div className='mx-2'>
        <div className='flex flex-row max-md:flex-col min-md:justify-end mb-1 items-center mx-2'>
          <div>
            <FilterInput showCalendarInput={false} setFilterNumber={setFilterName} placeholder={sysText701035}>
              <MultiSelect key={isRefresh} value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
            </FilterInput>
          </div>
        </div>
        <div className='flex items-center text-center max-md:flex-col'>
          <div className='min-md:ml-auto space-x-2 px-2'>
            <Button label={sysText701013} severity='info' raised onClick={() => setShowAddCard(true)}></Button>
            {(permissions === 3 || permissions === 9) &&
              <Button label={sysText701014} severity='info' raised onClick={() => setShowBatchEdit(true)}></Button>            
            }
          </div>
        </div>
        
      </div>

      <div className="p-4 recordWrap tableWrap">
        <div className=''>
          <DataTable value={cardSettingData} scrollable scrollHeight={tableScrollY} >
            {visibleColumns.map((row,index) =>             
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
            )}
          </DataTable>
        </div>      

        <GatewayDialog gatewayList={gatewayList} setGatewayList={setGatewayList} />
        <InfoDialog info={info} setInfo={setInfo} />
        <PictureDialog/>
        {/* <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> */}


      </div>

      {/* 新增卡片 */}
      {showAddCard && <AddCardNcc setShowAddCard={setShowAddCard} />}

      {/* 編輯卡片 */}
      {showEditCard && <EditCardNcc setShowEditCard={setShowEditCard} editCardData={editCardData} />}

      {/* 刪除卡片 */}
      {showDeleteCard && <DeleteCard setShowDeleteCard={setShowDeleteCard} deleteCardData={deleteCardData} />}

      {/* 批量修改 (與非卡共用)*/}
      {showBatchEdit && <BatchEditNcc setShowBatchEdit={setShowBatchEdit}></BatchEditNcc>}

    </div>
  );
}

export default CardSettingCc