import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { web_03_ncc_complete_order, web_05_ncc_exception_order } from '../../../api/api';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { useFormik } from 'formik';
import useSystemText from '../../../helper/useSystemText';

const RecordNccComplete = (props) => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {setShowComplete, detailData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation() 
  const [confirmBtn, setConfirmBtn] = useState(true)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText10004 = useSystemText(10004)

  const sysText201006 = useSystemText(201006)
  const sysText201007 = useSystemText(201007)
  const sysText201009 = useSystemText(201009)
  const sysText201026 = useSystemText(201026)
  const sysText201027 = useSystemText(201027)
  const sysText201040 = useSystemText(201040)
  const sysText201041 = useSystemText(201041)
  
  const exceptionHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: detailData?.訂單編號
    }
       
    try {
      const {listResponse, status, statusText} = await web_05_ncc_exception_order(filterData)

      if (status === 200) {   
        setIsRefresh(prev => !prev)      
        showToast("success", "" + status, `${sysText201041}: \n${detailData?.訂單編號}` )          
        setShowComplete(false)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }

  const completeOrder = async () => {   
    setIsLoading(true)

    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      ordernumber: detailData.訂單編號,
      orderprice: detailData.訂單金額,
      info: detailData.員工資訊,        
      web_03_ncc_confirmpeople: Cookies.get("cookie_uuid"),
      merchantno: detailData.商戶號,
      notifyurl: "",
      notify_message: "",
      order_uuid: detailData.uuid,
      gateway_id: detailData.通道id       
    };    

    try {        
      const { listResponse, status, statusText } = await web_03_ncc_complete_order(filterData);       
      
      

      if (status === 200) {
        setIsRefresh(prev => !prev)                     
        showToast("success", "" + status, `${sysText201040}: \n${detailData.訂單編號}` )
        setShowComplete(false)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }      
  }  

  //U1 U8需填入付款人姓名與訂單金額才可送出
  const formik = useFormik({
    initialValues: {
      nameInput: '',
      amountInput: 0,
    },
    validate: (data) => {
        let errors = {};      

        if (!data.nameInput) {								
          errors.nameInput = '必填';
        }

        if (!data.amountInput) {								
          errors.amountInput = '必填';
        }  

        return errors;
    },
    onSubmit: (data) => {
      completeOrder()      
    }
  });

  //U1 U8判斷付款人姓名與訂單金額是否符合
  useEffect(()=>{   
    if(formik.values.nameInput == detailData.付款人資訊 && formik.values.amountInput == detailData.訂單金額) {      
      setConfirmBtn(false)
    }else {
      setConfirmBtn(true)
    }

  },[formik.values.nameInput, formik.values.amountInput, detailData])  

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[250px] bg-customColor-bgGray rounded-lg'>
          <form className='flex flex-col' onSubmit={formik.handleSubmit}>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText201026}</div>

            {(permissions === 1 || permissions === 2 || permissions === 8) &&
              <div className='p-6 space-y-4 flex-1'>
                <p>{sysText201027}：</p>
                <p className='text-yellow-500'>{detailData.訂單編號}</p>
                <div>
                  <label htmlFor="nameInput">{sysText201007}</label>
                  <input name="nameInput" type="text" value={formik.values.nameInput} autoComplete='off' onChange={formik.handleChange} className='w-full' />
                </div>             
                <div>
                  <label htmlFor="amountInput">{sysText201009}</label>
                  <input name="amountInput" type="text" value={formik.values.amountInput} autoComplete='off' onChange={formik.handleChange} className='w-full' />
                </div>
                
              </div>
            }

            {(permissions === 3 || permissions === 9) &&
              <div className='p-6 space-y-4 flex-1'>
                <p>{sysText201006}：</p>
                <p className='text-yellow-500'>{detailData.訂單編號}</p>
                <h2 className='text-xl text-red-500'>*{sysText201027}*</h2>             
              </div>
            }

            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '80px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowComplete(false)}></Button>
              {(permissions === 1 || permissions === 8) &&
                <Button style={{width: '80px'}} label={sysText10002} type="submit" disabled={confirmBtn} severity='info' raised></Button>              
              }
              {(permissions === 3 || permissions === 9) &&
                <Button style={{width: '80px'}} label={sysText10002} onClick={completeOrder} severity='info' raised></Button>              
              }
              <Button style={{width: '120px'}} label={sysText10004} disabled={detailData.訂單狀態 !== 0} severity='danger' raised onClick={exceptionHandler}></Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default RecordNccComplete;