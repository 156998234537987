import { useContext } from "react";
import SystemTextContext from "../context/systemTextContext";

function useSystemText(id) {

  const systemText = useContext(SystemTextContext);

  if (!systemText) {
    return "loading..."; // 可以根据需要显示“加载中”或其他占位文本
  }

  const getDataForID = systemText.find(item => item.id === id);
  return getDataForID ? getDataForID.翻譯後語言 : "NoData";
}

export default useSystemText