import React, { useContext, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import ToastContext from '../../context/ToastContext';
import { Button } from 'primereact/button';
import useSystemText from '../../helper/useSystemText';
import { Dropdown } from 'primereact/dropdown';

const FilterInput = (props) => {

  const {
    setFilterNumber = () => {},
    setFilterDate = () => {},
    setFirst = () => {},
    setPage = () => {},
    showCalendarInput = true,
    showSearchInput = true,
    placeholder = "",
    defaultDate = [],
    searchField = "",
    setSearchField = null,
    searchFieldOption = []
  } = props
  
  const [dates, setDates] = useState(defaultDate);
  const [searchInput, setSearchInput] = useState("")
  const calendarRef = useRef(null)
  const {setIsRefresh} = useContext(ToastContext)

  const sysText10005 = useSystemText(10005)
  const sysText10006 = useSystemText(10006)
  const sysText10007 = useSystemText(10007)
  const sysText10044 = useSystemText(10044)

  const dateInputHandler = (e) => {  
    setDates(e.target.value)   
  }   

  const searchInputHandler = () => {   
    setFilterNumber(searchInput)
    setPage(0) 
    setFirst(0)
    let start_date = null
    let end_date = null

    //判斷日期是否有選擇結束日期，若沒有預設當天24點
    if(dates?.[0]) {
      if(dates?.[1]) {
        start_date = new Date(dates[0]).getTime()      
        end_date = new Date(new Date(dates[1]).setHours(23, 59, 59, 999)).getTime()      
        setFilterDate([start_date, end_date])
      }else {
        start_date = new Date(dates[0]).getTime()      
        end_date = new Date(new Date(dates[0]).setHours(23, 59, 59, 999)).getTime()      
        setFilterDate([start_date, end_date])
      }
      
      
    }        
  }

  const clearInputHandler = () => {
    setSearchInput("")
    setDates([])
    setFilterDate([]) 
    setFilterNumber("")
  }

  return (
    <div className='flex w-full py-1'>
      <div className='relative ml-auto flex flex-row max-md:flex-col my-1 items-center max-2xl:m-auto min-xl:space-x-1 max-2xl:space-y-1'>
        {props.children}

        {showCalendarInput && 
          <div className="flex justify-content-center h-10 mx-2 max-xl:w-full max-2xl:my-2">
            <Calendar ref={calendarRef} className='custom-calendar w-full' placeholder={sysText10044} value={dates} onChange={(e) => {dateInputHandler(e)}} selectionMode="range" readOnlyInput dateFormat="yy/mm/dd" maxDate={new Date()}/>
          </div>        
        }        

        <div className='flex flex-row items-center'>
          {showSearchInput &&
            <div className='max-md:w-full'>
              {setSearchField && <Dropdown className='custom-dropdown absolute h-10 w-28' value={searchField} onChange={(e) => {setSearchField(e.value)}} options={searchFieldOption} optionLabel='name' optionValue='value'></Dropdown>}
              <input onKeyDown={(e) => {if(e.key === "Enter") searchInputHandler()}} className={`bg-customColor-bgLightGray px-2 h-10 w-56 ${setSearchField ? 'pl-32' : ''}`} type="search" value={searchInput} onChange={(e) => {setSearchInput(e.target.value.trim())}} placeholder={placeholder}></input>
            </div>
          }
         
          <div className='flex flex-row justify-center max-md:w-full max-md:mt-2 max-md:hidden'>
            <Button label={sysText10005} onClick={searchInputHandler} severity='secondary' className="flex h-8 text-sm mx-2 rounded-lg justify-center items-center cursor-pointer whitespace-nowrap"/>
            <Button label={sysText10006} onClick={clearInputHandler} severity='danger' className="flex h-8 text-sm mx-2 rounded-lg justify-center items-center cursor-pointer whitespace-nowrap"/>
            <Button label={sysText10007} onClick={() => setIsRefresh(prev => !prev)} severity='success' className="flex h-8 text-sm mx-2 rounded-lg justify-center items-center cursor-pointer whitespace-nowrap"/>            
          </div>

          <div className='flex flex-row justify-center max-md:w-full max-md:mt-2 min-md:hidden'>
            <div onClick={searchInputHandler} className="flex text-sm mx-2 justify-center items-center cursor-pointer"><i className="pi pi-search"/></div>
            <div onClick={clearInputHandler} className="flex text-sm text-red-800 mx-2 rounded-lg justify-center items-center cursor-pointer"><i className="pi pi-times"/></div>
            <div onClick={() => setIsRefresh(prev => !prev)} className="flex text-sm text-green-700 mx-2 justify-center items-center cursor-pointer"><i className="pi pi-refresh"/></div>
          </div>             
        </div>             

        
      </div>             
    </div>

    
  );
};

export default FilterInput;