import React, { useContext, useEffect, useState } from 'react';
import MenuContext from '../context/menuContext';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { imgOnError } from '../helper/imgOnError';
import Marquee from 'react-fast-marquee';
import { web_76_get_marquee } from '../api/api';
import LoadingContext from '../context/loadingContext';
import ToastContext from '../context/ToastContext';
import useSystemText from '../helper/useSystemText';


function Navbar() {

  const {showMenu, setShowMenu} = useContext(MenuContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()		    
  const navigate = useNavigate();
  const [marqueeText, setMarqueeText] = useState(null)

  const sysText10010 = useSystemText(10010)

  const menuHandler = () => {
    setShowMenu(!showMenu)
  }

  const handleLogout = () => {
    Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
    navigate('/login');
  };

  useEffect(() => {
    const web76_fetchData = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        allornot: "0",        
      }
       
      try {
        const { listResponse, status, statusText } = await web_76_get_marquee(filterData);        
        
        // 

        if (status === 200) {     
          
          if(listResponse) {
            let lastData = listResponse[listResponse.length - 1]
            setMarqueeText(lastData?.內容訊息)            
          }
        } else {
          // showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    if(Cookies.get("cookie_uuid") && Cookies.get("cookie_hash")) {
      web76_fetchData()
    }
  }, [location, isRefresh])


  return (
    <div className="navbarWrap sticky flex justify-center items-center top-0 w-full h-20 max-2xl:h-12 bg-[#242526] px-6 shadow-sm shadow-[#a2a2a195] z-[99]">      
			<ul className="flex flex-row items-center w-full h-full">
        <li className="max-2xl:hidden flex flex-row items-center whitespace-nowrap cursor-pointer" onClick={() => {window.location.href="/home"}}>
          <div className="flex flex-row w-16 h-12">
            <img className="max-h-12 max-w-12" src="/luna.svg"/>            
          </div>          
          <h1 className='text-3xl'>米汇宝科技</h1>
        </li>

        <li className="min-2xl:hidden h-full flex justify-center items-center">
          <div onClick={menuHandler} className="flex w-10 h-10 justify-center items-center border border-gray-600 rounded-md cursor-pointer">
            <i className="pi pi-bars "></i>            
          </div>
        </li>

        <li className='w-[78%] max-md:w-[70%]'>
          {marqueeText &&  
            <Marquee className='text-yellow-300 text-xl' speed={100}>       
              <div>{marqueeText + "　　　　　　　　　　　　　　　"}</div>               
            </Marquee>
          }       
        </li>
        
        {!location.pathname.includes("/login") ? 
          <li className="ml-auto h-full flex justify-center items-center">
            <div onClick={handleLogout} className="flex w-20 h-10 text-xl rounded-lg justify-center items-center border border-rose-900  bg-red-950 hover:bg-red-700 hover:duration-100 cursor-pointer">
              {sysText10010}
            </div>
          </li>
          : null
        }
            
      </ul>
    </div>
  );
}

export default Navbar;