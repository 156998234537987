import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { useFormik } from 'formik';
import { web_122_update_checkout_3p_parameter, web_123_get_checkout_3p_parameter, web_86_get_brushing_order, web_87_update_brushing_order } from '../../../api/api';
import useJson from '../../../helper/useJson';
import HelpIcon from '../../UI/helpIcon';
import useSystemText from '../../../helper/useSystemText';
import useHelpText from '../../../helper/useHelpText';

const RiskControlSetting = (props) => {

  const {setShowRiskControlSetting} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const {jsonToObject} = useJson()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText706010 = useSystemText(706010)
  const sysText706011 = useSystemText(706011)
  const sysText706012 = useSystemText(706012)
  const sysText706013 = useSystemText(706013)
  const sysText706014 = useSystemText(706014)
  const sysText706015 = useSystemText(706015)
  const sysText706016 = useSystemText(706016)
  const sysText706017 = useSystemText(706017)
  const sysText706018 = useSystemText(706018)
  const sysText706019 = useSystemText(706019)
  const sysText706020 = useSystemText(706020)
  const sysText706021 = useSystemText(706021)
  const sysText706022 = useSystemText(706022)
  const sysText706024 = useSystemText(706024)
  const sysText706025 = useSystemText(706025)
  const sysText706028 = useSystemText(706028)
  const sysText706029 = useSystemText(706029)

  const helpText2 = useHelpText(2)

  const formik = useFormik({
    initialValues: {
      limit_duration: 0,
      limit_duration_again: 0,
      acquisition_amount_seconds: 0,
      acquisition_amount: 0,
      acquisition_number_seconds: 0,
      acquisition_number: 0,
      paid_amount_seconds: 0,
      paid_amount: 0,
      paid_number_seconds: 0,
      paid_number: 0,
      unpaid_amount_seconds: 0,
      unpaid_amount: 0,
      unpaid_number_seconds: 0,
      unpaid_number: 0,
      checkout_frequency: 0,
      checkout_seconds: 0

    },
    validate: (data) => {
        let errors = {};
       
        const floatRegExp = /^\d{1,10}(\.\d{1,2})?$/
        const numberRegExp = /^\d{1,10}$/

        if (data.limit_duration === null) {
          errors.limit_duration = '必填'
        }else if(!numberRegExp.test(data.limit_duration)) {								
          errors.limit_duration = '必须为数字';
        }

        if (data.limit_duration_again === null) {
          errors.limit_duration_again = '必填'
        }else if(!numberRegExp.test(data.limit_duration_again)) {								
          errors.limit_duration_again = '必须为数字';
        }

        if (data.acquisition_amount_seconds === null) {
          errors.acquisition_amount_seconds = '必填'
        }else if(!numberRegExp.test(data.acquisition_amount_seconds)) {								
          errors.acquisition_amount_seconds = '必须为数字';
        }

        if (data.acquisition_amount === null) {
          errors.acquisition_amount = '必填'
        }else if(!floatRegExp.test(data.acquisition_amount)) {								
          errors.acquisition_amount = '必须为数字';
        }

        if (data.acquisition_number_seconds === null) {
          errors.acquisition_number_seconds = '必填'
        }else if(!numberRegExp.test(data.acquisition_number_seconds)) {								
          errors.acquisition_number_seconds = '必须为数字';
        }
        if (data.acquisition_number === null) {
          errors.acquisition_number = '必填'
        }else if(!numberRegExp.test(data.acquisition_number)) {								
          errors.acquisition_number = '必须为数字';
        }

        if (data.paid_amount_seconds === null) {
          errors.paid_amount_seconds = '必填'
        }else if(!numberRegExp.test(data.paid_amount_seconds)) {								
          errors.paid_amount_seconds = '必须为数字';
        }

        if (data.paid_amount === null) {
          errors.paid_amount = '必填'
        }else if(!floatRegExp.test(data.paid_amount)) {								
          errors.paid_amount = '必须为数字';
        }

        if (data.paid_number_seconds === null) {
          errors.paid_number_seconds = '必填'
        }else if(!numberRegExp.test(data.paid_number_seconds)) {								
          errors.paid_number_seconds = '必须为数字';
        }

        if (data.paid_number === null) {
          errors.paid_number = '必填'
        }else if(!numberRegExp.test(data.paid_number)) {								
          errors.paid_number = '必须为数字';
        }

        if (data.unpaid_amount_seconds === null) {
          errors.unpaid_amount_seconds = '必填'
        }else if(!numberRegExp.test(data.unpaid_amount_seconds)) {								
          errors.unpaid_amount_seconds = '必须为数字';
        }

        if (data.unpaid_amount === null) {
          errors.unpaid_amount = '必填'
        }else if(!floatRegExp.test(data.unpaid_amount)) {								
          errors.unpaid_amount = '必须为数字';
        }

        if (data.unpaid_number_seconds === null) {
          errors.unpaid_number_seconds = '必填'
        }else if(!numberRegExp.test(data.unpaid_number_seconds)) {								
          errors.unpaid_number_seconds = '必须为数字';
        }

        if (data.checkout_frequency === null) {
          errors.checkout_frequency = '必填'
        }else if(!numberRegExp.test(data.checkout_frequency)) {								
          errors.checkout_frequency = '必须为数字';
        }    

        if (data.checkout_seconds === null) {
          errors.checkout_seconds = '必填'
        }else if(!numberRegExp.test(data.checkout_seconds)) {								
          errors.checkout_seconds = '必须为数字';
        }           
        
        return errors;
    },
    onSubmit: (data) => {
      
            
      const editReceive = async () => {      
        setIsLoading(true)

        const filterData = {     
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          limit_duration: data.limit_duration,
          limit_duration_again: data.limit_duration_again,
          acquisition_amount_seconds: data.acquisition_amount_seconds,
          acquisition_amount: data.acquisition_amount,
          acquisition_number_seconds: data.acquisition_number_seconds,
          acquisition_number: data.acquisition_number,
          paid_amount_seconds: data.paid_amount_seconds,
          paid_amount: data.paid_amount,
          paid_number_seconds: data.paid_number_seconds,
          paid_number: data.paid_number,
          unpaid_amount_seconds: data.unpaid_amount_seconds,
          unpaid_amount: data.unpaid_amount,
          unpaid_number_seconds: data.unpaid_number_seconds,
          unpaid_number: data.unpaid_number
        };        

        try {
          
          const { listResponse, status, statusText } = await web_87_update_brushing_order(filterData);       
          
          
  
          if (status === 200) {    
            setIsRefresh(prev => !prev)
            setShowRiskControlSetting(false)                 
            showToast("success", "" + status, `${sysText706028}` )              
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }            
      
      const editCheckout = async () => {      
        setIsLoading(true)
        const filterData = {     
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          frequency: data.checkout_frequency,
          seconds: data.checkout_seconds
        };        
        
        try {
          
          const { listResponse, status, statusText } = await web_122_update_checkout_3p_parameter(filterData);       
          
          
  
          if (status === 200) {    
            setIsRefresh(prev => !prev)
            setShowRiskControlSetting(false)        
            showToast("success", "" + status, `${sysText706029}` )                   
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }

      editReceive()   
      editCheckout() 
    }
  });

const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    const web86_fetchData = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")
      }
              
      try {
        const { listResponse, status, statusText } = await web_86_get_brushing_order(filterData);        
        
        

        if (status === 200) {     
          formik.setFieldValue("limit_duration", listResponse["風控封禁-時長"].幾秒)
          formik.setFieldValue("limit_duration_again", listResponse["風控封禁-時長"].再送單延長幾秒)            
          formik.setFieldValue("acquisition_amount_seconds", listResponse["風控封禁-收單金額"].幾秒內)
          formik.setFieldValue("acquisition_amount", listResponse["風控封禁-收單金額"].超過)  
          formik.setFieldValue("acquisition_number_seconds", listResponse["風控封禁-收單次數"].幾秒內)
          formik.setFieldValue("acquisition_number", listResponse["風控封禁-收單次數"].超過)            
          formik.setFieldValue("paid_amount_seconds", listResponse["風控封禁-已付金額"].幾秒內)
          formik.setFieldValue("paid_amount", listResponse["風控封禁-已付金額"].超過)  
          formik.setFieldValue("paid_number_seconds", listResponse["風控封禁-已付次數"].幾秒內)
          formik.setFieldValue("paid_number", listResponse["風控封禁-已付次數"].超過)            
          formik.setFieldValue("unpaid_amount_seconds", listResponse["風控封禁-未付金額"].幾秒內)
          formik.setFieldValue("unpaid_amount", listResponse["風控封禁-未付金額"].超過)      
          formik.setFieldValue("unpaid_number_seconds", listResponse["風控封禁-未付次數"].幾秒內)
          formik.setFieldValue("unpaid_number", listResponse["風控封禁-未付次數"].超過)             
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    } 

    const web123_fetchData = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")
      }
              
      try {
        const { listResponse, status, statusText } = await web_123_get_checkout_3p_parameter(filterData);        
        
        if (status === 200) {     
          formik.setFieldValue("checkout_frequency", listResponse["checkout_3p 單日IP最大次數"])
          formik.setFieldValue("checkout_seconds", listResponse["checkout_3p 重複調用阻擋秒數"])                 
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    } 

    web86_fetchData()
    web123_fetchData()
  }, [isRefresh])

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px]  max-md:w-full bg-customColor-bgGray rounded-lg'>
          <form onSubmit={formik.handleSubmit} className="flex flex-col h-[600px] overflow-auto">     
            <div className='flex flex-row border-b border-gray-500 text-lg p-6'>{sysText706010} <span className='ml-auto'><HelpIcon tooltipText={helpText2}></HelpIcon></span></div>
            <div className='p-6 space-y-2 overflow-y-scroll'>      
              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706011}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="limit_duration">{sysText706012}</label>
                    <input className="w-48 p-2" name="limit_duration" value={formik.values.limit_duration} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('limit_duration')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="limit_duration_again">{sysText706013}</label>
                    <input className="w-48 p-2" name="limit_duration_again" value={formik.values.limit_duration_again} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('limit_duration_again')}
                  </div>
                </div>
                
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706014}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="acquisition_amount_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="acquisition_amount_seconds" value={formik.values.acquisition_amount_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('acquisition_amount_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="acquisition_amount">{sysText706016}</label>
                    <input className="w-48 p-2" name="acquisition_amount" value={formik.values.acquisition_amount} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('acquisition_amount')}
                  </div>
                </div>               
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706017}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="acquisition_number_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="acquisition_number_seconds" value={formik.values.acquisition_number_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('acquisition_number_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="acquisition_number">{sysText706018}</label>
                    <input className="w-48 p-2" name="acquisition_number" value={formik.values.acquisition_number} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('acquisition_number')}
                  </div>
                </div>               
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706019}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="paid_amount_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="paid_amount_seconds" value={formik.values.paid_amount_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('paid_amount_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="paid_amount">{sysText706016}</label>
                    <input className="w-48 p-2" name="paid_amount" value={formik.values.paid_amount} onChange={formik.handleChange} type="text" autoComplete="off"></input>{getFormErrorMessage('limit_duration')}
                    {getFormErrorMessage('paid_amount')}
                  </div>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706020}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="paid_number_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="paid_number_seconds" value={formik.values.paid_number_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('paid_number_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="paid_number">{sysText706018}</label>
                    <input className="w-48 p-2" name="paid_number" value={formik.values.paid_number} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('paid_number')}
                  </div>
                </div>                
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706021}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="unpaid_amount_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="unpaid_amount_seconds" value={formik.values.unpaid_amount_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('unpaid_amount_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="unpaid_amount">{sysText706016}</label>
                    <input className="w-48 p-2" name="unpaid_amount" value={formik.values.unpaid_amount} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('unpaid_amount')}
                  </div>
                </div>               
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706022}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">
                    <label htmlFor="unpaid_number_seconds">{sysText706015}</label>
                    <input className="w-48 p-2" name="unpaid_number_seconds" value={formik.values.unpaid_number_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('unpaid_number_seconds')}
                  </div>
                  <div className="flex flex-col m-1">
                    <label htmlFor="unpaid_number">{sysText706018}</label>
                    <input className="w-48 p-2" name="unpaid_number" value={formik.values.unpaid_number} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('unpaid_number')}
                  </div>
                </div>               
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706024}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">                    
                    <input className="w-48 p-2" name="checkout_frequency" value={formik.values.checkout_frequency} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('checkout_frequency')}
                  </div>                  
                </div>               
              </div>

              <div className="flex flex-col space-y-2">
                <h2 className='font-bold'>{sysText706025}</h2>
                <div className='flex flex-row flex-wrap p-2'>
                  <div className="flex flex-col m-1">                    
                    <input className="w-48 p-2" name="checkout_seconds" value={formik.values.checkout_seconds} onChange={formik.handleChange} type="text" autoComplete="off"></input>
                    {getFormErrorMessage('checkout_seconds')}
                  </div>                  
                </div>               
              </div>
            </div>  
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity="secondary" onClick={() => setShowRiskControlSetting(false)}></Button>
              <Button type="submit" style={{width: "120px"}} label={sysText10002} severity="info"></Button>
            </div>                  
          </form>      
        </div>
      </Dialog>
    </>
  );
};

export default RiskControlSetting;