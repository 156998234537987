import React, { useContext, useEffect, useState } from "react";
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_70_ncc_purchase_order_reversal, web_70_purchase_order_reversal } from "../../api/api";
import useSystemText from "../../helper/useSystemText";

const PurchaseReversal = () =>  {    

    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()
    const [ordernumberInput, setOrdernumberInput] = useState("")
    const [ordernumberNccInput, setOrdernumberNccInput] = useState("")

    const sysText304001 = useSystemText(304001)
    const sysText304002 = useSystemText(304002)
    const sysText304003 = useSystemText(304003)
    const sysText304004 = useSystemText(304004)
    
    // const confirmHandler = async() => {
    //   setIsLoading(true)
    //   const filterData = {     
    //     cookie_uuid: Cookies.get("cookie_uuid"),
    //     cookie_hash: Cookies.get("cookie_hash"),
    //     pur_ordernumber: ordernumberInput        
    //   }  

    //   try {
        
    //     const { listResponse, status, statusText } = await web_70_purchase_order_reversal(filterData);       
        
    //     

    //     if (status === 200) {                     
    //       showToast("success", "" + status, `采购单冲正 子单号${filterData.pur_ordernumber}` )
    //       setIsRefresh(prev => !prev)
    //     } else {
    //       showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
    //     }
    //   } catch (error) {        
    //     console.error(location.pathname, error);            
    //   } finally {
    //     setIsLoading(false)
    //   }
    // }

    const confirmHandlerNcc = async() => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        pur_ordernumber: ordernumberNccInput        
      }  

      try {
        
        const { listResponse, status, statusText } = await web_70_ncc_purchase_order_reversal(filterData);       
        
        

        if (status === 200) {                     
          showToast("success", "" + status, `${sysText304004}: \n${filterData.pur_ordernumber}` )
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
   
    return (

        <div className="systeminformationWrap card flex flex-col justify-content-center">
          {/* <div className="top-10 left-16 relative w-28 text-xl text-gray-400 bg-[#18191a]">采购单冲正 </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4 w-60">
                <div className="flex flex-col">
                  <label htmlFor="reserve">{sysText304002}</label>              
                  <input name="reserve" className="w-96" type="text" value={ordernumberInput} onChange={(e) => {setOrdernumberInput(e.target.value)}} autoComplete="off"/>                      
                </div>
                <div>
                  <Button className="w-48 ml-auto" label={sysText304003} disabled={ordernumberInput === ""} severity="info" raised onClick={confirmHandler}></Button>
                </div>
              </div>             
            </div>     
          </div> */}

          <div className="top-10 left-16 relative w-40 text-xl text-gray-400 bg-[#18191a]">{sysText304001} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4 w-60">
                <div className="flex flex-col">
                  <label htmlFor="reserve">{sysText304002}</label>              
                  <input name="reserve" className="w-96" type="text" value={ordernumberNccInput} onChange={(e) => {setOrdernumberNccInput(e.target.value)}} autoComplete="off"/>                      
                </div>
                <div>
                  <Button className="w-48 ml-auto" label={sysText304003} disabled={ordernumberNccInput === ""} severity="info" raised onClick={confirmHandlerNcc}></Button>
                </div>
              </div>             
            </div>     
          </div>          
        </div>
    )
}

export default PurchaseReversal;