import React, {useContext, useState} from 'react';
import  Dialog from '../../dialog'
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_112_update_3p_def } from '../../../api/api';
import { useFormik } from 'formik';
import HelpIcon from '../../UI/helpIcon';
import useSystemText from '../../../helper/useSystemText';

const ThirdPartyResultEdit = (props) => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {editData, setShowEdit} = props

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText803002 = useSystemText(803002)
  const sysText803009 = useSystemText(803009)
  const sysText803013 = useSystemText(803013)
  const sysText803014 = useSystemText(803014)
  const sysText803015 = useSystemText(803015)
  
  const formik = useFormik({
    initialValues: {
      thirdPartyName: editData.三方名稱,
      purchaseQuota: editData.採購額度,
      proxy_ip: editData.代理IP
    },

    validate: (data) => {
        let errors = {};              
        const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/ 
        const ipPortRegex = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\:([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/;


        if(!data.thirdPartyName) {
          errors.thirdPartyName = '必填';
        }else if (data.thirdPartyName.length > 30) {								
          errors.thirdPartyName = '不可超過30字';
        }

        if(data.purchaseQuota === null) {
          errors.purchaseQuota = '必填';
        }else if (!numberRegExp.test(data.purchaseQuota)) {								
          errors.purchaseQuota = '必须为数字';
        }

        if (data.proxy_ip && !ipPortRegex.test(data.proxy_ip)) {								
          errors.proxy_ip = 'ex: 127.0.0.1:8000';
        }

        return errors;
    },
    
    onSubmit: (data) => {     

      const confirmHandler = async() => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          display_name: data.thirdPartyName,
          purchase_quota: data.purchaseQuota,
          def3pname: editData.三方套件名稱,
          proxy_ip: data.proxy_ip     
        }
               
        try {
          const { listResponse, status, statusText} = await web_112_update_3p_def(filterData);          
                    
    
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            setShowEdit(false)          
            showToast("success", "" + status, `${sysText803014}: \n${editData.三方套件名稱}` )
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }

      confirmHandler()
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <Dialog>
      <div className='mx-auto w-[35vw] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          <div className='flex flex-row items-center p-6 border-b border-gray-700'>
            <h2 className='text-lg'>{sysText803013}</h2>
            {/* <div className='ml-auto'>
              <HelpIcon tooltipText={helpText.userManageEditUser_text}/>
            </div> */}
          </div>     

          <div className='p-6 space-y-2 overflow-y-scroll max-h-[600px]'>              
            
            <div>
              <label htmlFor="thirdPartyName">{sysText803002}</label>                       
              <input id="thirdPartyName" name="thirdPartyName" className="p-2 w-full" value={formik.values.thirdPartyName} onChange={formik.handleChange} placeholder="" autoComplete="off"/>
              {getFormErrorMessage('thirdPartyName')}  
            </div> 

            <div>
              <label htmlFor="purchaseQuota">{sysText803009}</label>                       
              <input id="purchaseQuota" name="purchaseQuota" className="p-2 w-full" value={formik.values.purchaseQuota} onChange={formik.handleChange} placeholder="" autoComplete="off"/>
              {getFormErrorMessage('purchaseQuota')}  
            </div> 

            <div>
              <label htmlFor='proxy_ip'>{sysText803015}</label>
              <input name="proxy_ip" value={formik.values.proxy_ip} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
              {getFormErrorMessage('proxy_ip')} 
            </div>                   
            
          </div>

          <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowEdit(false)}></Button>
              <Button type="submit" style={{width: '120px'}} label={sysText10002} severity='info' raised></Button>             
          </div>
        </form>
        
      </div>
    </Dialog>
  );
};

export default ThirdPartyResultEdit;