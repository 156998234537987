import React, { useEffect, useContext, useState } from 'react';
import Dialog from '../../dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import Cookies from 'js-cookie';
import { insert_conversation, show_conversation, web_29_ncc_Order_Detail } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { imgOnError } from '../../../helper/imgOnError';
import useCopy from '../../../helper/useCopy';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';
import Conversation from '../../conversation';

const RecordDetailNcc = (props) => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {setShowDetail, detailData} = props
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [otherDetailData, setOtherDetailData] = useState({})
  const {copyHandler} = useCopy()
  
  const [payerName, setPayerName] = useState("");
  const [displayPayerName, setDisplayPayerName] = useState("");
  const helpTextPicture = useHelpText(0)
  const [showConversation, setShowConversation] = useState(false)  
  const [contentInput, setContentInput] = useState("")
  const [conversationData, setConversationData] = useState([])

  const sysText10009 = useSystemText(10009)

  const sysText201003 = useSystemText(201003)
  const sysText201006 = useSystemText(201006)
  const sysText201007 = useSystemText(201007)
  const sysText201008 = useSystemText(201008)
  const sysText201013 = useSystemText(201013)
  const sysText201014 = useSystemText(201014)
  const sysText201015 = useSystemText(201015)
  const sysText201016 = useSystemText(201016)
  const sysText201017 = useSystemText(201017)
  const sysText201018 = useSystemText(201018)
  const sysText201019 = useSystemText(201019)
  const sysText201020 = useSystemText(201020)
  const sysText201021 = useSystemText(201021)
  const sysText201022 = useSystemText(201022)
  const sysText201023 = useSystemText(201023)
  const sysText201024 = useSystemText(201024)

  const onSubmit = async() => {

    let filterData = {}

    if(contentInput.slice(0, 3) === "/c ") {
      filterData = {       
        order_id: detailData.訂單編號,
        sender: "c",
        content: contentInput.slice(3, contentInput.length)
      };
    }else {
      filterData = {       
        order_id: detailData.訂單編號,
        sender: "s",
        content: contentInput
      };
    }    

    try {
      const { listResponse, status, statusText } = await insert_conversation(filterData);          
                

      if (status === 200) { 
        setIsRefresh(prev => !prev)
        showToast("success", "成功送出讯息")        
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )        
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }  
  }

  const imgDetail = (item) => {
    return (
    <>
      <HelpIcon tooltipText={helpTextPicture}></HelpIcon>
      <a href={`https://water168.shop/pic/${item.img}`} target='_blank' rel='noopener noreferrer'>     
        <img src={`https://water168.shop/pic/${item.img}`} className='max-w-[100px] max-h-[100px]' onError={({currentTarget}) => imgOnError(currentTarget)}></img>
      </a>
    </>
    )
  }

  useEffect(() => {
    if (otherDetailData) {
      const name = otherDetailData.付款人姓名;
      setPayerName(name);
      setDisplayPayerName(name ? (name.length < 2 ? '*' : name[0] + '*' + name.slice(2)) : '');
    }
  }, [otherDetailData]);

  useEffect(() => {      
    setIsLoading(true)
    const filterData = {       
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: detailData?.訂單編號,     
    };

    const fetchData = async() => {
            
      try {
        const { listResponse, status, statusText } = await web_29_ncc_Order_Detail(filterData);          
                  

        if (status === 200) { 
          setOtherDetailData(listResponse)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)      
  }, [location]);

  useEffect(() => {      
    setIsLoading(true)
    const filterData = {       
      order_id: detailData.訂單編號
    };

    const fetchData = async() => {
            
      try {
        const { listResponse, status, statusText } = await show_conversation(filterData);          
                  

        if (status === 200) { 
          setConversationData(listResponse.conversation)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)    
  }, [detailData, isRefresh]);

  return (
    <>      
      <Dialog>
        <div className='mx-auto w-[70vw] bg-customColor-bgGray rounded-xl overflow-y-scroll max-md:w-full max-h-[80vh]'>
          <div className='flex w-full p-4'>
            <ul className='flex justify-between items-center w-full border-b border-gray-700 py-4 px-8 max-md:px-2 max-md:text-xs'>
              <li className='text-lg max-md:text-lg'>{sysText201013}</li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/api/kuaidi/yuantong/view/${detailData?.訂單編號}`}>{sysText201014}</a></li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/t/kuaidi/tracker/${detailData?.訂單編號}`}>{sysText201015}</a></li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/api/kuaidi/yuantong/viewdd/${detailData?.訂單編號}`}>{sysText201016}</a></li>
              <li className='text-blue-500'><a href={`https://expressdeles.com/#/detail/${detailData?.訂單編號}`}>{sysText201017}</a></li>
            </ul>
          </div>
          <div className='flex flex-col border-b px-8 border-gray-700'>                  
            <div className='flex flex-wrap'>
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>{sysText201006}</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.訂單編號}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>收款資訊</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.員工資訊}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>寄件人</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.寄件人}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>{sysText201007}</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.付款人資訊}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>收件地址</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.付款人地址}</p>
                  </li>                          
                </ul>              
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>{sysText201003}</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.訂單時間}</p>
                  </li>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>寄件地址</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.寄件地址}</p>
                  </li>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>快递单号</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.運送號碼}</p>
                  </li>
                                
                </ul>
              
            </div>

            <div className='w-full tableWrap mt-2'>
              <DataTable value={otherDetailData.產品資訊} tableStyle={{ minWidth: '50rem' }}  scrollable scrollHeight="220px">
                <Column field="name" header={sysText201018}></Column>
                <Column field="img" header={sysText201019} body={imgDetail}></Column>
                <Column field="qty" header={sysText201020}></Column>
                <Column field="price" header={sysText201021}></Column>                      
              </DataTable>      
            </div>

            <div className='ml-auto my-4 w-[25%] max-2xl:w-full'>
              <ul>
                <li className='flex justify-between m-2'><p>{sysText201022}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.訂單金額 + otherDetailData.折扣}</p></li>
                <li className='flex justify-between m-2'><p>{sysText201023}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{otherDetailData.折扣}</p></li>
                <li className='flex justify-between m-2'><p>运费</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer text-red-500" onClick={(e) => copyHandler(e.target.innerText)}>¥ 0</p></li>
                <li className='flex justify-between m-2'><p>{sysText201024}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.訂單金額}</p></li>
              </ul>
            </div>     

           
            {showConversation && 
              <div className='flex flex-col w-full h-full'>
                <div className='p-4'>
                  <div className='w-full bg-gray-300 text-black rounded-xl space-y-4'>
                    <div className='max-h-[70vh] overflow-y-scroll p-4 space-y-4'>
                      {conversationData?.map((item) => (
                        item.sender == "c" ? 
                        <div className='flex flex-row w-[50%] space-x-1'>
                          <div className='flex justify-center items-center'>
                            <div className='rounded-full overflow-hidden'>
                              <img className='min-w-[24px] max-w-[24px]' src="/images/conversationImg.jpg"></img>
                            </div>
                          </div>
                          <div className='contentWrap bg-white rounded-xl p-2 text-sm'>
                            {item.content}
                          </div>
                        </div>
                        : item.sender == "s" ?
                        <div className='flex flex-row justify-end w-[50%] ml-auto space-x-1'>  
                            <div className='contentWrap bg-white rounded-xl p-2 text-sm'>
                              {item.content}
                            </div>
                            <div className='flex justify-center items-center w-10 h-8 '>
                              <div className='rounded-full overflow-hidden'>
                                <img className='min-w-[24px] max-w-[24px]' src="/images/conversationImg.jpg"></img>
                              </div>
                            </div>
                        </div>
                        : null
                      ))}
                    </div>

                    <div className='contentInput flex flex-row items-center rounded-xl'>
                      <textarea className='contentInput w-full h-full' value={contentInput} onChange={(e) => setContentInput(e.target.value)} placeholder='输入讯息'></textarea>
                      <div className='justify-end'>
                        <Button className='w-16 whitespace-nowrap' severity='info' label='送出' onClick={onSubmit}></Button>
                      </div>
                    </div>
                    
                  </div>              
                </div>
              </div>    
            }                    
            
          </div>
          <div className='flex flex-auto w-full justify-around items-center py-2'>
            <Button className='flex justify-center w-36' label='查看对话' onClick={() => { setShowConversation(prev => !prev) }} size="small" severity="info" raised></Button>
            <Button className='flex justify-center w-36' label='关闭' onClick={() => {setShowDetail(false)}} size="small" severity="secondary" raised></Button>
          </div> 
        </div>
      </Dialog>       
      
    </>
    
  );
};

export default RecordDetailNcc;