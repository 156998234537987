import React, { useState, useContext, useRef, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import LoadingContext from '../../../context/loadingContext';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { imgOnError } from '../../../helper/imgOnError';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';

const UserManageQRcode = (props) => {

  const {createKeyData, setShowQRcode,  qrcodeData} = props
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast} = useContext(ToastContext)
  const location = useLocation()
  const helpText = useHelpText(9)
    
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-h-[500px] bg-customColor-bgGray rounded-lg max-md:w-full'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between border-b border-gray-500 text-lg p-6'>
              <p>QRcode <span className='text-yellow-500'>{createKeyData?.用戶名稱}</span></p>
              <div><HelpIcon tooltipText={helpText}/></div>
            </div>
            <div className='p-6 flex flex-col'>             
              <div className='flex flex-col justify-center items-center space-y-4'>
                <div className='p-2'>                          
                  {qrcodeData ? <img className='max-w-[180px] max-h-[180px]' onError={({currentTarget}) => imgOnError(currentTarget)} src={"data:image/png;base64, " + qrcodeData}/>
                  :  
                  <div className='flex justify-center items-center w-[180px] h-[180px] text-gray-500 text-sm bg-customColor-bgLightGray'>FAILED</div>                    
                  }                  
                </div>
                {/* <p className='text-sm text-red-500'>*此QRcode只会显示一次，请妥善保存</p>                                            */}
              </div>                      
            </div>
            <div className='border-t border-gray-500 flex justify-center items-center py-2'>
              <Button style={{width: '120px'}} label='OK' severity='info' onClick={() => setShowQRcode(false)}></Button>
                            
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserManageQRcode;