import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';

import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import { web_30_ncc_get_transfer_data, web_31_ncc_transfer_pur_sub_order } from '../../../api/api';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import Dialog from '../../dialog';
import useJson from '../../../helper/useJson';


const PurchaseRecordNccTransfer = (props) => {

  const {setShowTransfer, processSubData, setShowProcess} = props
  const [purchaseTransferData, setPurchaseTransferData] = useState([]) 
  const [showConfirm, setShowConfirm] = useState(false)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext) 
  const [selectCard, setSelectCard] = useState([])
  const {jsonToObject} = useJson()  

  const transferHandler = () => {
    const transferData = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),     
        trapso_confirmpeople: Cookies.get("cookie_uuid"),               
        trapso_pur_ordernumber: processSubData.子單單號,        
        trapso_pur_staff_info: selectCard.資訊
      };
      
      try {
        const { listResponse, status, statusText } = await web_31_ncc_transfer_pur_sub_order(filterData);        
        
        if (status === 200) {         
          setIsRefresh(prev => !prev)          
          showToast("success", "" + status, `成功转派子单: ${processSubData.子單單號}` )
          setShowConfirm(false)
          setShowTransfer(false)
          setShowProcess(false)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }    
    transferData()    
  }

  const selectCardHandler = (e, item) => {
    setSelectCard(item)
  }

  useEffect(() => {      
    
    const web30_fetchData = async() => {
      setIsLoading(true)
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),    
        seatd_pur_productamount: processSubData.訂單金額,
        gateway_id: processSubData.gateway
      };
            
      try {
        const { listResponse, status, statusText} = await web_30_ncc_get_transfer_data(filterData);          
                  

        if (status === 200) {   
          const newData = listResponse.data.map(row => {
            const info = jsonToObject(row.資訊)
            return {...row, ...info}
          })                  
          setPurchaseTransferData(newData)         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }   
    web30_fetchData()      
  }, []);
  
  return (
    <>
      <Dialog>
        <div className='mx-auto min-w-[350px] max-h-[800px] max-md:max-h-[800px] bg-customColor-bgGray rounded-xl overflow-y-scroll'>
          <div className='flex flex-col'>      
            <div className='flex items-center p-6 border-b border-gray-700'>
              <h2 className='text-lg'>转派订单: <span className='text-yellow-500'> {processSubData.子單單號}</span></h2>
            </div>          
            <div className="tableWrap h-[60vh] overflow-scroll px-8">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">               
                <tbody className='space-y-4'>
                  {purchaseTransferData.map((item, index) => 
                    <tr key={index} className="bg-customColor-bgDark text-white flex flex-row">
                      <td className="w-4 p-4 flex items-center justify-center basis-1/12">
                        <div className="flex items-center">
                          <input name="radio" type="radio" onChange={(e) => selectCardHandler(e, item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500"/>                     
                        </div>
                      </td>                    
                      <td className="px-6 py-4 flex flex-row flex-wrap items-center basis-9/12">
                        <div className='flex flex-row items-center min-w-[40%]'>
                          <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                            姓名   
                          </div>
                          <p>{item.姓名}</p>                            
                        </div>
                        <div className='flex flex-row items-center min-w-[40%]'>
                          <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                            银行名称
                          </div>
                          <p>{item.银行名称}</p>                            
                        </div>
                        <div className='flex flex-row items-center min-w-[40%]'>
                          <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                            卡号
                          </div>
                          <p>{item.卡号}</p>                            
                        </div>
                        <div className='flex flex-row items-center min-w-[40%]'>
                          <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                            余额
                          </div>
                          <p>{item.餘額}</p>                            
                        </div>
                      </td>                       
                    </tr>              
                  )} 
                </tbody>
              </table>
            </div> 

            <div className='flex w-full justify-around items-center border-t border-gray-700 p-4'>
              <Button style={{width: '120px'}} className='flex justify-center' label='取消转派' onClick={() => {setShowTransfer(false)}} severity="secondary" raised></Button>              
              <Button style={{width: '120px'}} className='flex justify-center' label='确认转派' disabled={selectCard.length <= 0} onClick={() => setShowConfirm(true)} severity="info" raised></Button>              
            </div> 
          </div>          
        </div>
      </Dialog>

    {/* 確認視窗 */}
    {showConfirm && 
      <Dialog>
      <div className='mx-auto min-w-[350px] max-h-[800px] max-md:max-h-[800px] bg-customColor-bgGray rounded-xl overflow-y-scroll'>
        <div className='flex flex-col'>
          <div className='p-4'>
            <h2 className='text-xl'>请确认转派人员资讯:</h2>  
          </div>
                           
          <div className="p-8">            
            <p>訂單編號: {processSubData.子單單號}</p>
            <p>员工姓名: {selectCard.用戶名稱}</p>
            <p>银行名称: {selectCard.银行名称}</p>
            <p>员工卡号: {selectCard.卡号}</p>
            <p>余额: {selectCard.餘額}</p>
          </div> 

          <div className='flex w-full justify-end items-center border-t border-gray-700 p-2 space-x-4'>
            <Button className='flex justify-center' label='取消' onClick={() => {setShowConfirm(false)}} severity="secondary" raised></Button>              
            <Button className='flex justify-center' label='确认' onClick={transferHandler} severity="info" raised></Button>              
          </div>       
        
        </div>
        
      </div>
    </Dialog>
    }
    
    </>
   
  );
};

export default PurchaseRecordNccTransfer;