import React, { createContext, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const [isRefresh, setIsRefresh] = useState(false)

  const showToast = (severity, summary, detail, statusText, life = 5000) => {
    // 若有statusText則表示有傳入錯誤訊息
    if (statusText && statusText != "") {
      toast.current.show({ severity, summary, detail: statusText, life});
    }else {
      toast.current.show({ severity, summary, detail, life});
    }     
  };

  return (
    <ToastContext.Provider value={{showToast, isRefresh, setIsRefresh}}>
      <Toast ref={toast} position="bottom-right" />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContext