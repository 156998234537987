import React, { useState, useContext, useRef } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { web_49_update_shop_am_record_state } from '../../../api/api';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import useSystemText from '../../../helper/useSystemText';

const WithdrawComplete = (props) => {

  const {setShowComplete, completeData} = props 
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText10008 = useSystemText(10008)

  const sysText203030 = useSystemText(203030)
  const sysText203031 = useSystemText(203031) 
  const sysText203032 = useSystemText(203032)
  const sysText203034 = useSystemText(203034) 

  const withdrawConfirmHandler = () => {
    setIsLoading(true)
    const filterData = {       
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: completeData.流水編號 ?? "",
      state: "2"     
    };

    const revoke = async() => {
             
      try {
        const { listResponse, status, statusText } = await web_49_update_shop_am_record_state(filterData);          
                  

        if (status === 200) {
          setIsRefresh(prev => !prev) 
          showToast("success", "" + status, `确认提领 編號:${completeData.流水編號}` )
          setShowComplete(false)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
        setShowComplete(false)
      }
    }
    revoke(filterData)
  }


  const withdrawRevokeHandler = () => {
    setIsLoading(true)
    const filterData = {       
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: completeData?.流水編號,
      state: "3"     
    };

    const revoke = async() => {
             
      try {
        const { listResponse, status, statusText } = await web_49_update_shop_am_record_state(filterData);          
                  

        if (status === 200) { 
          setIsRefresh(prev => !prev)
          setShowComplete(false)
          showToast("success", "" + status, `确认駁回 編號:${completeData.流水編號}` )
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)        
      }
    }
    revoke(filterData)
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[200px] max-h-[800px] max-md:max-h-[500px] overflow-y-scroll bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText203034}</div>
            <div className='p-6 flex flex-col space-y-4'>              
              <div>
                <p className='text-sm'>{sysText203030}:</p>
                <p className='text-yellow-300'>{completeData?.流水編號}</p>   
              </div>
              <div>
                <p className='text-sm'>{sysText203031}:</p>
                <p className='text-yellow-300'>{completeData?.商戶編號}</p>   
              </div>
              <div>
                <p className='text-sm'>{sysText203032}:</p>
                <p className='text-yellow-300'>{completeData?.提領金額}</p>   
              </div>                      
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center h-20'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowComplete(false)}></Button>
              <Button style={{width: '120px'}} label={sysText10002} severity='info' raised onClick={withdrawConfirmHandler}></Button>
              <Button style={{width: '120px'}} label={sysText10008} severity='danger' onClick={withdrawRevokeHandler} raised></Button>              
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default WithdrawComplete;