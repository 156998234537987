import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { web_115_select_3p_gateway_list, web_18_get_merchant, web_92_edit_3pset, web_94_get_3pset_uuid_card, web_95_get_3pset_def } from '../../../api/api';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import useSystemText from '../../../helper/useSystemText';

const ThirdPartyEdit = (props) => {

  const {editData, setShowEdit} = props
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [selectState, setSelectState] = useState(String(editData.狀態))
  const [merchantnoOption, setMerchantnoOption] = useState([])
  const [uuidOption, setUuidOption] = useState([])
  const [cardnumberData, setCardnumberData] = useState({})
  const [cardnumberOption, setCardnumberOption] = useState([])
  const [defOption, setDefOption] = useState([])
  const [defOptionOrigin, setDefOptionOrigin] = useState([])
  const [gatewayIdOption, setGatewayIdOption] = useState([])

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText705001 = useSystemText(705001)
  const sysText705005 = useSystemText(705005)
  const sysText705006 = useSystemText(705006)
  const sysText705007 = useSystemText(705007)
  const sysText705008 = useSystemText(705008)
  const sysText705010 = useSystemText(705010)
  const sysText705011 = useSystemText(705011)
  const sysText705012 = useSystemText(705012)
  const sysText705013 = useSystemText(705013)
  const sysText705014 = useSystemText(705014)
  const sysText705015 = useSystemText(705015)
  const sysText705016 = useSystemText(705016)
  const sysText705017 = useSystemText(705017)

  const stateOption = [
    {
      name: "停用",
      value: "0"
    },
    {
      name: "启用",
      value: "1"
    }   
  ]  

  const formik = useFormik({
    initialValues: {
        uuid: editData.uuid,
        username: editData.用戶姓名,
        secondname: editData.次要姓名,
        cardnumber: editData.卡號,
        // dailyCash: editData.日收款額上限,
        // dailyCount: editData.日收款次數上限,
        // balance: editData.餘額,
        merchantnoCol: editData.專收,
        merchantnoPur: editData.專付,
        selectDefs: Object.keys(editData.def),
        defInputs: Object.values(editData.def),
        gatewayId: editData.能使用的通道id,
        state: editData.state        

    },
    validate: (data) => {
      let errors = {};      
      const numberRegExp = /^-?\d{1,10}(\.\d{1,2})?$/

      if (data.uuid === "") {								
        errors.uuid = '必填';
      }

      if (data.username === "") {								
        errors.username = '必填';
      }
      
      if (data.secondname === "") {								
        errors.secondname = '必填';
      }
      
      if (!data.cardnumber) {								
        errors.cardnumber = '必填';
      }

      // if (data.dailyCash === "") {								
      //   errors.dailyCash = '必填';
      // }else if(!numberRegExp.test(data.dailyCash)) {
      //   errors.dailyCash = '必須為數字';
      // }

      // if (data.dailyCount === "") {								
      //   errors.dailyCount = '必填';
      // }else if(!numberRegExp.test(data.dailyCount)) {
      //   errors.dailyCount = '必須為數字';
      // } 
      
      // if (data.balance === "") {								
      //   errors.balance = '必填';
      // }else if(!numberRegExp.test(data.balance)) {
      //   errors.balance = '必須為數字';
      // }     

      // if (data.merchantnoCol.length > 0 && data.merchantnoPur.length > 0) {								
      //   errors.merchantnoCol = '專收專付只能擇一';
      //   errors.merchantnoPur = '專收專付只能擇一';
      // } 
      
      // if (data.merchantnoCol.length == 0 && data.merchantnoPur.length == 0) {								
      //   errors.merchantnoCol = '必填';
      //   errors.merchantnoPur = '必填';
      // }   

      if (data.selectDefs.length === 0) {								
        errors.selectDefs = '必填';
      }

      if (data.defInputs.length === 0) {								
        errors.defInputs = '必填';
      }

      if (data.defInputs.reduce((sum, defInput) => parseFloat(sum) + parseFloat(defInput), 0) < 1) {
        errors.defInputs = '加总不可小于1';
      }    

      if (data.defInputs.some(defInput => defInput == "")) {
        errors.defInputs = '必填';
      }         

      if (data.state === "") {								
        errors.state = '必填';
      }

      return errors;
    },
    onSubmit: async(data) => {  
      setIsLoading(true)


      // 將 data.defInputs && data.selectDefs 做成def json
      let newDeftoJson = {}
      if(data.defInputs.length === data.selectDefs.length) {
        data.selectDefs.map((key, index) => {
          newDeftoJson[key + ""] = data.defInputs[index] + ""
        })
      }

       newDeftoJson = JSON.stringify(newDeftoJson)

      let filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id: editData.id,
        new_3_pset_uuid: data.uuid,
        new_3_pset_username: data.username,
        new_3_pset_secondname: data.secondname,
        representscardnumber: data.cardnumber,
        paymentamountdaylimit: data.dailyCash,
        paymentcountdaylimit: data.dailyCount,        
        def_list: newDeftoJson,
        state: data.state,
        gateway_id_list: data.gatewayId         
      };
      
      // if(data.merchantnoPur.length > 0) {
      //   filterData.pur_merchantno = data.merchantnoPur
      // }

      // if(data.merchantnoCol.length > 0) {
      //   filterData.col_merchantno = data.merchantnoCol
      // }       
      
      try {
        
        const { listResponse, status, statusText } = await web_92_edit_3pset(filterData);       
        
        

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEdit(false)              
          showToast("success", `${sysText705017}`)           
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  // def 下拉用
  const arrayChangeHandler = (e, name, index) => {
    let newArray = formik.values[name]
    newArray[index] = e.target.value
    formik.setFieldValue(name, newArray)
  }

  // 根据已选择的项动态调整每个下拉菜单的选项
  const getAvailableOptionsForIndex = (currentIndex) => {
    return defOption.filter(
      (option) => !formik.values.selectDefs.includes(option) || formik.values.selectDefs[currentIndex] === option
    );
  };

  const plusButtonClickHandler = () => {
    let newSelectDefs = [...formik.values.selectDefs]
    newSelectDefs.push("")
    formik.setFieldValue("selectDefs", newSelectDefs)
    let newDefInputs = [...formik.values.defInputs]
    newDefInputs.push("")
    formik.setFieldValue("defInputs", newDefInputs)
  }

  const minusButtonClickHandler = (index) => {
    let newSelectDefs = [...formik.values.selectDefs]
    newSelectDefs.splice(index, 1)
    formik.setFieldValue("selectDefs", newSelectDefs)
    let newDefInputs = [...formik.values.defInputs]
    newDefInputs.splice(index, 1)
    formik.setFieldValue("defInputs", newDefInputs)
  }
  
  useEffect(() => {

    const fetchMerchantno = async() => {
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };  
      
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_18_get_merchant(filterData);        
        
        
  
        if (status === 200) {
          setMerchantnoOption(listResponse.merchant)         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }

    const fetchUuidAndCardnumber = async() => {
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };  
      
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_94_get_3pset_uuid_card(filterData);        
        
        
  
        if (status === 200) {
          setUuidOption(Object.keys(listResponse))
          setCardnumberData(listResponse)

          // 將 listResponse {uuid: 卡號(array), username, secondname} 整理
          if(formik.values.uuid) {
            const uuidHasCardData = listResponse[formik.values.uuid]
            const newCardnunberOption = uuidHasCardData?.卡號
            setCardnumberOption(newCardnunberOption)
            formik.setFieldValue("username", uuidHasCardData?.username)
            formik.setFieldValue("secondname", uuidHasCardData?.secondname)
          }
         
          formik.setFieldValue("cardnumber", editData.卡號)          
          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }

    const fetchDef = async() => {
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        gateway_type_id: editData.通道類型      
      };  
      
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_95_get_3pset_def(filterData);        
        
        
  
        if (status === 200) {
          setDefOption(listResponse)
          setDefOptionOrigin(listResponse)         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }

    const fetchGatewayId = async() => {
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        gateway_type_id: editData.通道類型       
      };  
      
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_115_select_3p_gateway_list(filterData);        
        
        
  
        if (status === 200) {
          const data = listResponse.map((item) => {return {"name": `${item.display_name} - ${item.merchantno}`, "value": item.id}})
          setGatewayIdOption(data)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }

    fetchMerchantno()
    fetchUuidAndCardnumber()
    fetchDef()
    fetchGatewayId()
  }, [isRefresh, editData])

  useEffect(() => {
    formik.setFieldValue("cardnumber", "")
    // 將 listResponse {uuid: 卡號(array), username, secondname} 整理
    if(formik.values.uuid) {
      const uuidHasCardData = cardnumberData[formik.values.uuid]
      const newCardnunberOption = uuidHasCardData?.卡號
      setCardnumberOption(newCardnunberOption)

      formik.setFieldValue("username", uuidHasCardData?.username)
      formik.setFieldValue("secondname", uuidHasCardData?.secondname)
    }
  },[formik.values.uuid, editData])

  useEffect(() => {   
    
    let newDefOptions = [...defOptionOrigin];

    // if (formik.values.merchantnoPur.length > 0) {
    //   newDefOptions = newDefOptions.filter(option => option.includes('_pur'));
    // }

    // if (formik.values.merchantnoCol.length > 0) {
    //   newDefOptions = newDefOptions.filter(option => !option.includes('_pur'));
    // }

    setDefOption(newDefOptions);

  },[formik.values.merchantnoPur, formik.values.merchantnoCol, defOptionOrigin])

  // useEffect(() => {   
    
  //   let newSelectDefs = [...formik.values.selectDefs];

  //   if (formik.values.selectDefs.length > 0) {
  //     newSelectDefs = formik.values.selectDefs.filter((item, index) => formik.values.selectDefs.indexOf(item) === index)   
  //     formik.setFieldValue("selectDefs", newSelectDefs)
  //   }      

  // },[formik.values.selectDefs])
      
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[70vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          
          <div className='border-b border-gray-700 p-4 text-lg'>编辑三方</div>
         
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>

            <div className='flex flex-col'>
              <label htmlFor='uuid'>{sysText705001}</label>
              <Dropdown name="uuid" value={formik.values.uuid} autoComplete='off' onChange={formik.handleChange} options={uuidOption} className='custom-dropdown' type="text" />
              {getFormErrorMessage('uuid')}
            </div>  

            <div className='flex flex-col'>
              <label htmlFor='username'>{sysText705011}</label>
              <input name="username" value={formik.values.username} disabled={true} autoComplete='off' className='opacity-50 cursor-not-allowed' onChange={formik.handleChange} type="text" />
              {getFormErrorMessage('username')}
            </div>  

            <div className='flex flex-col'>
              <label htmlFor='secondname'>{sysText705012}</label>
              <input name="secondname" value={formik.values.secondname} disabled={true} autoComplete='off' className='opacity-50 cursor-not-allowed' onChange={formik.handleChange} type="text" />
              {getFormErrorMessage('secondname')}
            </div> 

            <div className='flex flex-col'>
              <label htmlFor='cardnumber'>{sysText705013}</label>
              <Dropdown name="cardnumber" value={formik.values.cardnumber} autoComplete='off' onChange={formik.handleChange} options={cardnumberOption} className='custom-dropdown' type="text" />
              {getFormErrorMessage('cardnumber')}
            </div>            

            {/* <div className='flex flex-col'>
              <label htmlFor='dailyCash'>{sysText705014}</label>
              <input name="dailyCash" value={formik.values.dailyCash} autoComplete='off' onChange={formik.handleChange} type="text" />
              {getFormErrorMessage('dailyCash')}
            </div>  

            <div className='flex flex-col'>
              <label htmlFor='dailyCount'>{sysText705015}</label>
              <input name="dailyCount" value={formik.values.dailyCount} autoComplete='off' onChange={formik.handleChange} type="text" />
              {getFormErrorMessage('dailyCount')} 
            </div>  

            <div className='flex flex-col'>
              <label htmlFor='balance'>{sysText705005}</label>
              <input name="balance" value={formik.values.balance} disabled={true} autoComplete='off' onChange={formik.handleChange} className='cursor-not-allowed opacity-50' type="text" />
              {getFormErrorMessage('balance')}
            </div>                */}
            
            {/* <div className='flex flex-col'>
              <label htmlFor='merchantnoCol'>{sysText705006}</label>
              <MultiSelect key={isRefresh} name="merchantnoCol" value={formik.values.merchantnoCol} autoComplete='off' onChange={formik.handleChange} options={merchantnoOption} className='custom-dropdown' type="text" />
              {getFormErrorMessage('merchantnoCol')}
            </div>

            <div className='flex flex-col'>
              <label htmlFor='merchantnoPur'>{sysText705007}</label>
              <MultiSelect key={isRefresh} name="merchantnoPur" value={formik.values.merchantnoPur} autoComplete='off' onChange={formik.handleChange} options={merchantnoOption} className='custom-dropdown' type="text" />
              {getFormErrorMessage('merchantnoPur')}
            </div> */}

            {(formik.values.selectDefs.length > 0 ? formik.values.selectDefs : [{}]).map((item, index) => (
              <div key={index} className='flex flex-col'>
                <label htmlFor='selectDefs'>{sysText705008}</label>
                <div className='flex flex-row space-x-4'>
                  <Dropdown name="selectDefs" value={formik.values.selectDefs[index]} autoComplete='off' onChange={(e) => {arrayChangeHandler(e, "selectDefs", index)}} options={getAvailableOptionsForIndex(index)} className='custom-dropdown w-64' type="text" />
                  <input name="defInputs" value={formik.values.defInputs[index]}  autoComplete='off' onChange={(e) => {arrayChangeHandler(e, "defInputs", index)}} type="number" />
                  <Button type='button' disabled={formik.values.selectDefs.length <= 1} icon="pi pi-minus" severity='danger' onClick={() => {minusButtonClickHandler(index)}}></Button>
                </div>
                
                {getFormErrorMessage('selectDefs')}
                {getFormErrorMessage('defInputs')}
              </div>
            ))}

            {/* {formik.values.selectDefs.map((item, index) => (
               
              )     
            )} */}
            <Button type='button' icon="pi pi-plus" severity="info" onClick={plusButtonClickHandler}></Button>

            <div className='flex flex-col'>
              <label htmlFor='gatewayId'>{sysText705010}</label>
              <MultiSelect key={isRefresh} name="gatewayId" value={formik.values.gatewayId} autoComplete='off' onChange={formik.handleChange} options={gatewayIdOption} optionLabel='name' optionValue='value' className='custom-dropdown' type="text" />
              {getFormErrorMessage('gatewayId')}
            </div> 

            <div className='flex flex-row space-x-4'>
              <div className='flex items-center space-x-4'>
                <label htmlFor='state'>{sysText705016}</label>              
                <InputSwitch name="state" checked={formik.values.state == "1"} value={formik.values.state} onChange={(e) => {e.target.value ? formik.setFieldValue("state", "1") : formik.setFieldValue("state", "0")}} className="p-inputswitch" />
              </div>                            
            </div>                                       
          </div>
                 

          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => {setShowEdit(false)}}></Button>
            <Button type="submit" style={{width: "120px"}} label={sysText10002} disabled={isLoading} severity='info' raised></Button>
          </div>
        
        </form>
          
        </div>
      </Dialog>
    </>
  );
};

export default ThirdPartyEdit;