import React, { useState, useContext, useRef, useEffect } from 'react';
import { ToastContext } from '../../context/ToastContext';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { uploadfile } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import { imgOnError } from '../../helper/imgOnError';

const ImgInput = (props) => {

  const {fileImg1, setFileImg1, fileImg2, setFileImg2, defaultImg1 = null, defaultImg2 = null} = props
  const {showToast} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [previewImg1, setPreviewImg1] = useState("")
  const [previewImg2, setPreviewImg2] = useState("")
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const location = useLocation()

  const formatList = ["png", "jpg", "jpge",]

  const handleFileChange = (event, setFile, setPreview, defaultImg) => {

    if(event.target?.files[0]) {
      const file = event.target?.files[0];
      const extension = file.name.split('.').pop().toLowerCase();

      if (!formatList.includes(extension)) {
        showToast("warn", '警告', `檔案格式必須為 ${formatList.join("、")}！`);
        setFile(null);
        setPreview(null);
        event.target.value = null;
        return;
      }      
      setFile(file)

      const reader = new FileReader();
      reader.onloadend = () => {        
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }else {
      setFile(defaultImg)
      setPreview(defaultImg);
    }    
  };  

  return (
    <>
      <div className='flex flex-row space-x-2'>
        <div className='flex justify-center items-center max-w-[200px] h-[200px] border border-dashed border-gray-700 mb-6 1'>
          {previewImg1 ? (
            
             <img src={previewImg1} onError={({currentTarget}) => imgOnError(currentTarget)} style={{ maxWidth: '100%', height: '100%' }} />
            
          ) : (
            <p style={{ textAlign: 'center' }}>你的預覽图片將在這裡顯示</p>
          )}       
        </div>
        
        {(previewImg1 && fileImg1 && setFileImg2) &&
          <div className='flex justify-center items-center max-w-[200px] h-[200px] border border-dashed border-gray-700 mb-6 2'>
            {previewImg2 ? (
              
              <img src={previewImg2} onError={({currentTarget}) => imgOnError(currentTarget)} style={{ maxWidth: '100%', height: '100%' }} />
              
            ) : (
              <p style={{ textAlign: 'center' }}>你的預覽图片將在這裡顯示</p>
            )}       
          </div>
        }
      </div>
      <div>
        <label>图片1</label>
        <input className='mb-6 w-full' ref={fileInputRef1} type="file" accept="*" onChange={e => handleFileChange(e, setFileImg1, setPreviewImg1, defaultImg1)} />
      </div>
      
      {(previewImg1 && fileImg1 && setFileImg2) &&
      <div>
        <label>图片2</label>
        <input className='mb-6 w-full' ref={fileInputRef2} type="file" accept="*" onChange={e => handleFileChange(e, setFileImg2, setPreviewImg2, defaultImg2)} />
      </div>
      }      
    </>
  );
};

export default ImgInput;