import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import FilterInput from '../../components/UI/filterInput';
// import UserManageCard from '../../components/accountManage/userManage/userManageCard';
import UserManageAddUser from '../../components/accountManage/userManage/userManageAddUser';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserManageEditUser from '../../components/accountManage/userManage/userManageEditUser';
import { web_54_get_user_upper_and_down_list } from '../../api/api';
import Cookies from 'js-cookie';
import LoadingContext from '../../context/loadingContext';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import UserManageCreateKey from '../../components/accountManage/userManage/userManageCreateKey';
import UserManageRevokeKey from '../../components/accountManage/userManage/userManageRevokeKey';
import UserManageQRcode from '../../components/accountManage/userManage/userManageQRcode';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';
import useTableSize from '../../helper/useTableSize';


const UserManage = () => {

    const {isLoading, setIsLoading} = useContext(LoadingContext)
    const {showToast} = useContext(ToastContext)
    const {isRefresh, setIsRefresh} = useContext(ToastContext)
    const location = useLocation()
    const [userManageData, setUserManageData] = useState({})
    const [showEditUser, setShowEditUser] = useState(false)
    const [editUserData, setEditUserData] = useState({})
    const [showCreateKey, setShowCreateKey] = useState(false)
    const [createKeyData, setCreateKeyData] = useState({})
    const [showRevokeKey, setShowRevokeKey] = useState(false)
    const [revokeKeyData, setRevokeKeyData] = useState({})
    const [showAddUser, setShowAddUser] = useState(false)
    const [showQRcode, setShowQRcode] = useState(false)
    const [qrcodeData, setQRcodeData] = useState("")
    const [filterNumbr, setFilterNumber] = useState("")
    const [permissions] = useState(parseInt(Cookies.get("cookie_permissions"))); 
    const tableScrollY = useTableSize(50, 50)
    
    const sysText402001 = useSystemText(402001)
    const sysText402002 = useSystemText(402002)
    const sysText402003 = useSystemText(402003)
    const sysText402004 = useSystemText(402004) 
    const sysText402005 = useSystemText(402005)
    const sysText402006 = useSystemText(402006)
    const sysText402007 = useSystemText(402007)
    const sysText402008 = useSystemText(402008) 
    const sysText402009 = useSystemText(402009)
    const sysText402010 = useSystemText(402010)
    const sysText402011 = useSystemText(402011)
    const sysText402012 = useSystemText(402012)
    const sysText402028 = useSystemText(402028)
    const sysText402029 = useSystemText(402029)
    const sysText402030 = useSystemText(402030)
    
    const KeyColumn = (item) => {
      let label, severity = ""
      
      if(item.登入密鑰 === 1) {        
        severity = "success"
        return <Button className='pi pi-lock' label={label} severity={severity} text raised disabled={true}></Button>
      }else if(item.登入密鑰 === 0) {        
        severity = "danger"
        return <Button className='pi pi-lock-open' label={label} severity={severity} text raised disabled={true}></Button>
      }
    }
    
    const ActiveColumn = (item) => {              

      const showEditUserHandler = () => {
        setShowEditUser(true)
        setEditUserData(item)        
      } 
      
      const showCreateKeyHandler = () => {
        setShowCreateKey(true)
        setCreateKeyData(item)        
      } 

      const showRevokeKeyHandler = () => {
        setShowRevokeKey(true)
        setRevokeKeyData(item)        
      } 
      
      return (        
        <div className='activeBtn flex flex-row items-center space-x-2 justify-around'>
          <Button style={{whiteSpace: "nowrap"}} label={sysText402007} severity="warning" onClick={showEditUserHandler} raised />                    
          {permissions !== 3 && <Button style={{whiteSpace: "nowrap"}} label={sysText402008} severity="info" disabled={item.登入密鑰 == 1} onClick={showCreateKeyHandler} raised />}
          {permissions !== 3 && <Button style={{whiteSpace: "nowrap"}} label={sysText402009} severity="danger" disabled={item.登入密鑰 == 0} onClick={showRevokeKeyHandler} raised />}
                 
        </div>
      )
    }    

    const tableColumns = [
      {
        header: sysText402001,
        field: "uuid",
        style: {minWidth: "120px"},
        sortable: true
      },
      {
        header: sysText402002,
        field: "帳號",
        style: {minWidth: "120px"},
        sortable: true
      },
      {
        header: sysText402003,
        field: "用戶名稱",
        style: {minWidth: "120px"},
        sortable: true
      },
      {
        header: sysText402004,
        field: "層級",
        style: {minWidth: "80px"},
        sortable: true,
        enable: [3, 9]
      },
      {
        header: sysText402005,
        field: "登入密鑰",
        style: {minWidth: "120px"},
        sortable: true,
        body: KeyColumn
      },
      {
        header: sysText402006,
        field: "active",
        style: {minWidth: "120px"},
        body: ActiveColumn,
        enable: [3, 9],
        headerClassName: "center-header"
      }, 
    ]

    //透過欄位enable判斷是否顯示
    const filterColumnForUuid = tableColumns.filter(item => {
      return item.enable ?
        item.enable.includes(permissions) ? item : null
      : 
        item
    })
  
    const onColumnToggle = (event) => {
      let selectedColumns = event.value;
      let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
  
      setVisibleColumns(orderedSelectedColumns);
    };  
    
    const [visibleColumns, setVisibleColumns] = useState([])

useEffect(() => {
  setVisibleColumns(filterColumnForUuid)
}, [isRefresh])
    
    useEffect(() => {      
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),
        select: filterNumbr             
      };

      const fetchData = async() => {
        setIsLoading(true)       
        try {
          const { listResponse, status, statusText} = await web_54_get_user_upper_and_down_list(filterData);          
                    

          if (status === 200) {                     
            setUserManageData(listResponse)          
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      fetchData(filterData)       
    }, [filterNumbr, isRefresh]);

    return (
        <div className="p-4 recordWrap tableWrap">
          <div className='flex flex-wrap max-md:mb-4'>
            {/* <div className='flex justify-center items-center w-48 h-12 border border-gray-400 bg-customColor-btnGray rounded-md'>
              <span>当前利润百分比：? ％</span>
            </div> */}
                                 
            <div className='flex flex-row flex-wrap justify-center items-center w-full min-2xl:ml-auto max-2xl:w-[100%]'>                          
              <FilterInput setFilterNumber={setFilterNumber} showCalendarInput={false} placeholder={sysText402030}>              
                <MultiSelect key={isRefresh} value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
              </FilterInput>      
              
              <div className='min-2xl:ml-auto'>
                <Button label={sysText402010} style={{width: '90px'}} severity='info' raised onClick={() => setShowAddUser(true)}></Button>  
              </div>               
            </div>                        
          </div>

          <div className='flex flex-col space-y-4'>
            <div className='space-y-2'>
              <div className='flex justify-center items-center text-red-400 font-bold rounded-md w-10'>{sysText402028}</div>              

              <div className='flex flex-row'>
                <div className='flex flex-row flex-wrap p-2 border border-yellow-400 rounded-md text-center'>
                  <p className='px-4'>{sysText402001}：{userManageData.upper && userManageData.upper[0]?.uuid}</p>
                  <p className='px-4'>{sysText402002}：{userManageData.upper && userManageData.upper[0]?.帳號}</p>
                  <p className='px-4'>{sysText402003}：{userManageData.upper && userManageData.upper[0]?.用戶名稱}</p>
                </div>
              </div>
            </div>
            
            <div>
              <div className='flex justify-center items-center text-blue-400 font-bold rounded-md w-10'>{sysText402029}</div>              
              <div className=''>                       
                <DataTable removableSort value={userManageData.down} scrollHeight={tableScrollY} scrollable>
                  {visibleColumns.map((row, index) =>                                   
                    <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable}></Column>                   
                  )}
                </DataTable>
              </div>  
            </div>
           
          </div>
        
          {/* 新增用戶 */}
          {showAddUser && <UserManageAddUser setShowAddUser={setShowAddUser} addUserData={userManageData.upper}/>}
          {/* 編輯用戶 */}
          {showEditUser && <UserManageEditUser editUserData={editUserData} setShowEditUser={setShowEditUser}/>}
          {/* 生成登入驗證碼 */}
          {showCreateKey && <UserManageCreateKey setShowCreateKey={setShowCreateKey} createKeyData={createKeyData} setQRcodeData={setQRcodeData} setShowQRcode={setShowQRcode}/>}
          {/* 生成驗證碼後QRcode */}
          {showQRcode && <UserManageQRcode createKeyData={createKeyData} qrcodeData={qrcodeData} setShowQRcode={setShowQRcode}/>}
          {/* 移除登入驗證碼 */}
          {showRevokeKey && <UserManageRevokeKey setShowRevokeKey={setShowRevokeKey} revokeKeyData={revokeKeyData}/>}

        </div>
    );
}

export default UserManage