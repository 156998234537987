import React, { useContext, useEffect, useState } from 'react';
import { web_113_get_lang, web_114_update_lang, web_120_update_page_lang, web_121_get_page_lang } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import HelpIcon from '../../components/UI/helpIcon';
import useHelpText from '../../helper/useHelpText';
import { Dropdown } from 'primereact/dropdown';

const SystemTextSetting = () => {

  const [systemTextSettingData, setSystemTextSettingData] = useState([])
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [selectLangHTML, setSelectLangHTML] = useState()
  const [filterNumber, setFilterNumber] = useState("")
  const [searchInput, setSearchInput] = useState("")

  const helpText16 = useHelpText(16)
  const helpText18 = useHelpText(18)

  const langOption = ["en", "zh", "zh-CN", "zh-TW", "es", "fr", "de", "ja", "ko", "it", "ru", "ar", "sv", "nl", "pt", "pt-BR", "fi", "da", "pl", "hu", "el", "tr", "he", "id", "th", "vi", "hi", "bn"];

  const langHTMLSubmitHandler = async() => {
         
    setIsLoading(true)
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      lang: selectLangHTML   
    };            
          
    try {
      
      const { listResponse, status, statusText } = await web_120_update_page_lang(filterData);       
      
      

      if (status === 200) {    
        setIsRefresh(prev => !prev)                 
        showToast("success", "" + status, `成功设定系统語系 ${selectLangHTML}` )              
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)          
    }
  }

  const handleSubmit = async(row, event) => {
    event.preventDefault(); // 防止表单默认提交行为
    let formValue
    if(event.type === "keydown") {
      formValue = event.target.value
    }else if(event.type === "submit") {
      formValue = event.target.elements.valueInput.value
    }

    setIsLoading(true)
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      value: formValue,
      id: row.id  
    };            
          
    try {
      
      const { listResponse, status, statusText } = await web_114_update_lang(filterData);       
      
      

      if (status === 200) {    
        setIsRefresh(prev => !prev)                 
        showToast("success", "" + status, `成功设定系统文字 ${row.頁面名稱}` )              
      } else {
        showToast("error", "" + status, listResponse, statusText )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }    
  };

  const handleKeyDown = (e, row, submitEvent) => {
    if (e.key === 'Escape') {
      // 阻止默认行为和事件冒泡
      e.preventDefault();
      e.stopPropagation();
      // 手动触发表单提交
      handleSubmit(row, submitEvent);
    }
  };  

  //系統文字取值
  useEffect(() => {

    const getLang = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")
      }
              
      try {
        const { listResponse, status, statusText } = await web_113_get_lang(filterData);        
        
        

        if (status === 200) {     
          var data = listResponse.filter(item => item.id > 10000 && item.頁面名稱 && item.頁面名稱.includes(filterNumber))
          setSystemTextSettingData(data)        
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    getLang()

    const getLangHTML = async() => {
      try {
        const { listResponse, status, statusText } = await web_121_get_page_lang();        
        
        if (status === 200) {     
          setSelectLangHTML(listResponse)        
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    getLangHTML()
    
  }, [isRefresh, filterNumber])

  return (
    <div className='flex flex-col space-y-4 overflow-y-auto h-full'>
      <div className='flex flex-col w-60'>
        <div className='flex flex-row space-x-4'>
          <label htmlFor='langHTML' className='text-lg'>系统语系设定 </label>
          <HelpIcon tooltipText={helpText18}/>
        </div>
        
        <div className='flex flex-row items-center space-x-4 p-4'>
          <Dropdown name="langHTML" className='custom-dropdown w-48' value={selectLangHTML} onChange={(e) => {setSelectLangHTML(e.value)}} options={langOption}></Dropdown>
          <Button style={{minWidth: "80px", whiteSpace: "nowrap"}} onClick={langHTMLSubmitHandler} label="保存设定" severity="info"></Button>
        </div>
      </div>

      <div className='flex flex-row space-x-4 max-2xl:flex-col'>
        <div className='flex flex-row space-x-4'>
          <label htmlFor='langHTML' className='text-lg'>系统文字设定 </label>
          <HelpIcon tooltipText={helpText16}/>
        </div>
         
        <div className='flex flex-row'>
          <input onKeyDown={(e) => {if(e.key === "Enter") setFilterNumber(searchInput)}} className="bg-customColor-bgLightGray px-2 h-10 w-56" type="search" value={searchInput} onChange={(e) => {setSearchInput(e.target.value.trim())}} placeholder={"請輸入頁面名稱"}></input>
          <div onClick={() => setFilterNumber(searchInput)} className="flex text-sm mx-2 justify-center items-center cursor-pointer"><i className="pi pi-search"/></div>
        </div>
        
      </div>
      
      {systemTextSettingData.map(row => {
        return (
          <div key={row.id} className='flex flex-col p-4'>                       
            <form onKeyDown={(e) => handleKeyDown(e, row, e)} onSubmit={(event) => handleSubmit(row, event)}>              
              <label>{row.頁面名稱}</label>
              <div className='flex flex-row items-center space-x-4'>                                  
                <textarea className='bg-transparent cursor-not-allowed opacity-70 w-96' name="originInput" type="text" defaultValue={row.原始語言} disabled={true} />
                <textarea className='bg-transparent w-96' name="valueInput" type="text" defaultValue={row.翻譯過後語言}  />                                
                <Button type="submit" style={{minWidth: "80px", whiteSpace: "nowrap"}} label="保存设定" severity="info"></Button>
              </div>                         
            </form>          
          </div>
        )
      })}
    </div>
  );
};

export default SystemTextSetting;