import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { formatDate } from '../../helper/formatDate';

import FilterInput from '../../components/UI/filterInput';

import { web_136, web_137 } from '../../api/api'
import PaginatorUI from '../../components/UI/paginatorUI';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { SplitButton } from 'primereact/splitbutton';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';
import useCopy from '../../helper/useCopy';

const ComplaintList = () => {       
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [complaintListData , setComplaintListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [filterDate, setFilterDate] = useState([])    
  const [filterNumber, setFilterNumber] = useState("")    
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const [searchField, setSearchField] = useState("投訴單號")
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()  
  const {copyHandler} = useCopy()
  
  const sysText602001 = useSystemText(602001)
  const sysText602002 = useSystemText(602002)
  const sysText602003 = useSystemText(602003)
  const sysText602004 = useSystemText(602004)
  const sysText602005 = useSystemText(602005)
  const sysText602006 = useSystemText(602006)
  const sysText602007 = useSystemText(602007)
  const sysText602008 = useSystemText(602008)
  const sysText602009 = useSystemText(602009)
  const sysText602010 = useSystemText(602010)
  const sysText602011 = useSystemText(602011)
  const sysText602012 = useSystemText(602012)
  const sysText602013 = useSystemText(602013)
  const sysText602014 = useSystemText(602014)
  const sysText602015 = useSystemText(602015)
  const sysText602016 = useSystemText(602016)
  const sysText602017 = useSystemText(602017)
  const sysText602018 = useSystemText(602018)


  const searchFieldOption = [
    {name: sysText602002, value: "投訴單號"},
    {name: sysText602003, value: "系統單號"},
    {name: sysText602004, value: "訂單編號"},
    {name: sysText602011, value: "商戶號"},    
    {name: sysText602001, value: "三方套件"},    
  ]

  const ActiveColumn = (item) => {
    
    let label = ""
    let severity = ""    

    let dataToString = Object.values(item).join(",")

    switch(item.投訴狀態) {
      case 0:
        label = sysText602013
        severity = "warning"
        break
      
      case 1:
        label = sysText602014
        severity = "success"
        break

      case 2:
        label = sysText602015
        severity = "help"
        break

      case 9:
        label = sysText602016
        severity = "danger"
        break      
    }

    const updateStatus = async(statusId) => {
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),    
        complain_event_id: item.投訴單號,
        status: statusId,
        out_no: item.系統單號
      };  
      
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_137(filterData);          
                  

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          showToast("success", status, null, sysText602018)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    
    }

    const menuItem = [
      {
        id: 0,
        label: sysText602013,
        command: () => {updateStatus(0)}
      },
      {
        id: 1,
        label: sysText602014,
        command: () => {updateStatus(1)}
      },
      {
        id: 2,
        label: sysText602015,
        command: () => {updateStatus(2)}
      },
      {
        id: 9,
        label: sysText602016,
        command: () => {updateStatus(9)}
      }
    ]

    return (       
      
      <div className='activeBtn flex flex-col items-center justify-center p-0 w-full whitespace-nowrap'>                 
        <SplitButton label={label} severity={severity} model={menuItem.filter((row) => row.id != item.投訴狀態)} onClick={() => {}}/>                        
        <Button label={sysText602017} severity={"info"} onClick={() => copyHandler(dataToString)}/>                        
      </div>   
      
    )    
  }      

  const tableColumns = [
    {
      header: sysText602001,
      field: "三方套件",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602002,
      field: "投訴單號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602003,
      field: "系統單號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602004,
      field: "訂單編號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602005,
      field: "支付寶交易單號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602006,
      field: "投訴內容",
      style: {minWidth: "300px"},
    },
    {
      header: sysText602007,
      field: "投訴時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText602008,
      field: "訂單金額",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602009,
      field: "付款人資訊",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602010,
      field: "訂單時間",
      style: {minWidth: "100px"},
      body: (date) => formatDate(date.訂單時間)
    },
    {
      header: sysText602011,
      field: "商戶號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText602012,
      field: "active",
      style: {width: "140px"},
      body: ActiveColumn,
      headerClassName: "centered-header"
    },
  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState([])

useEffect(() => {
  setVisibleColumns(filterColumnForUuid)
}, [isRefresh])


  useEffect(() => {      
    const filterData = {      
      cookie_uuid: Cookies.get('cookie_uuid'),
      cookie_hash: Cookies.get('cookie_hash'),    
      select: filterNumber,     
      page: page + 1,
      limit: rows,
      field: searchField 
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText} = await web_136(filterData);          
                  

        if (status === 200) {                     
          setComplaintListData(listResponse.data)            
          setTotalCount(listResponse.count)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)      
  }, [first, rows, page, filterDate, filterNumber, isRefresh, searchField]);

  return (
    <div className="p-4 max-2xl:py-2 max-2xl:px-0 recordWrap tableWrap">
     
      {/* MultiSelect組件 value options裡面不能有function */}
      <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} showCalendarInput={false} placeholder={""} searchField={searchField} setSearchField={setSearchField} searchFieldOption={searchFieldOption}>        
        <MultiSelect key={isRefresh} value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:hidden custom-multiselect" display='chip'/>
      </FilterInput>

      <div className=''>
        <DataTable value={complaintListData} scrollable scrollHeight="550px">
          {visibleColumns.map((row,index) =>            
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>
          )}              
        </DataTable>
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
      </div>           

      
    </div>
  );
}

export default ComplaintList