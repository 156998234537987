import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { web_133, web_135 } from '../../api/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useSystemText from '../../helper/useSystemText';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';


const GatewayMonitoring = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [gatewayData, setGatewayData] = useState([])
  const [selectGateway, setSelectGateway] = useState("")
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation() 
  const [hourLabel, setHourLabel] = useState([])
  const [hourOrderCountData, setHourOrderCountData] = useState([])
  const [hourOrderFailCountData, setHourOrderFailCountData] = useState([])
  const [hourOrderAmountData, setHourOrderAmountData] = useState([])
  const [dailyLabel, setDailyLabel] = useState([])
  const [dailyOrderCountData, setDailyOrderCountData] = useState([])
  const [dailyOrderFailCountData, setDailyOrderFailCountData] = useState([])
  const [dailyOrderAmountData, setDailyOrderAmountData] = useState([])
  const [isTiming, setIsTiming] = useState(false)
  const [timeLeft, setTimeLeft] = useState(10 * 1000); // 初始化為 10 分鐘的毫秒數
  const [intervalId, setIntervalId] = useState(null); // 紀錄登出計時器ID
  const [selectType, setSelectType] = useState("1")

  const typeOption = [
    {
      label: "24h",
      value: "1"
    },
    {
      label: "30days",
      value: "2"
    }
  ]

  const sysText10007 = useSystemText(10007)

  const sysText802001 = useSystemText(802001)
  const sysText802002 = useSystemText(802002)
  const sysText802003 = useSystemText(802003)
  const sysText802004 = useSystemText(802004)
  const sysText802005 = useSystemText(802005)
  const sysText802006 = useSystemText(802006)
  const sysText802007 = useSystemText(802007)
  const sysText802008 = useSystemText(802008)
  const sysText802009 = useSystemText(802009)
  const sysText802010 = useSystemText(802010)
  const sysText802011 = useSystemText(802011)
  const sysText806001 = useSystemText(806001)
  const sysText806002 = useSystemText(806002)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  //計算收單成功數百分比
  const footer = (tooltipItems) => {
    let colOrderPercent = 0;  
    colOrderPercent = Math.round((tooltipItems[0].parsed.y / (tooltipItems[0].parsed.y + tooltipItems[1].parsed.y)) * 100)
    if(colOrderPercent) {
      return `${sysText802009}: ${colOrderPercent}%`;
    }else {
      return `${sysText802009}: 0%`;
    }
  };
  
  const hourOrderCountOptions = {
    maintainAspectRatio: false, //不固定寬高比
    responsive: true,
    interaction: {
      intersect: false,        
      mode: 'nearest',
      axis: "x"
    },     
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: sysText802002,
        font: {
          size: "30"
        }
      },
     
      tooltip: {
        mode: 'index',
        intersect: false,
        //顯示收單成功數百分比
        callbacks: {
          footer: footer
        }
      },
    },
    scales: {
      y: {
        min: 0, // 自定义 Y 轴的最小值
        suggestedMax: 5000, //預設最大值
        ticks: {
          stepSize: 500, // 自定义 Y 轴每个刻度的步长
        },      
      }
    },
  };

  const hourOrderCountDataSet = {
    labels: hourLabel,
    datasets: [
      {
        label: sysText802006,
        data: hourLabel.map((row, index) => hourOrderCountData[index]),
        borderColor: "rgba(255, 99, 132, 0.8)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",        
      },
      {
        label: sysText802007,
        data: dailyLabel.map((row, index) => hourOrderFailCountData[index]),
        borderColor: "rgba(255, 206, 86, 0.8)",
        backgroundColor: "rgba(255, 206, 86, 0.5)",
      }         
    ],
  };

  const hourOrderAmountOptions = {
    maintainAspectRatio: false, //不固定寬高比
    responsive: true,
    interaction: {
      intersect: false,        
      mode: 'nearest',
      axis: "x"
    },     
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: sysText802003,
        font: {
          size: "30"
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },     
    },
    scales: {
      y: {
        min: 0, // 自定义 Y 轴的最小值
        suggestedMax: 50000,       
        ticks: {
          stepSize: 5000, // 自定义 Y 轴每个刻度的步长
        },      
      }
    },
  };
    
  const hourOrderAmountDataSet = {
    labels: hourLabel,
    datasets: [
      {
        label: sysText802008,
        data: hourLabel.map((row, index) => hourOrderAmountData[index]),
        borderColor: "rgba(54, 162, 235, 0.8)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      }     
    ],
  };

  const dailyOrderCountOptions = {
    maintainAspectRatio: false, //不固定寬高比
    responsive: true,
    interaction: {
      intersect: false,        
      mode: 'nearest',
      axis: "x"
    },     
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: sysText802004,
        font: {
          size: "30"
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        //顯示收單成功數百分比
        callbacks: {
          footer: footer
        }
      },
      
    },
    scales: {
      y: {
        min: 0, // 自定义 Y 轴的最小值
        suggestedMax: 50000,
        ticks: {
          stepSize: 5000, // 自定义 Y 轴每个刻度的步长
        },      
      }
    },
  };

  const dailyOrderCountDataSet = {
    labels: dailyLabel,
    datasets: [
      {
        label: sysText802006,
        data: dailyLabel.map((row, index) => dailyOrderCountData[index]),
        borderColor: "rgba(75, 192, 192, 0.8)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: sysText802007,
        data: dailyLabel.map((row, index) => dailyOrderFailCountData[index]),
        borderColor: "rgba(255, 206, 86, 0.8)",
        backgroundColor: "rgba(255, 206, 86, 0.5)",
      }       
    ],
  };

  const dailyOrderAmountOptions = {
    maintainAspectRatio: false, //不固定寬高比
    responsive: true,
    interaction: {
      intersect: false,        
      mode: 'nearest',
      axis: "x"
    },     
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: sysText802005,
        font: {
          size: "30"
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },     
    },
    scales: {
      y: {
        min: 0, // 自定义 Y 轴的最小值
        suggestedMax: 5000000,
        ticks: {
          stepSize: 500000, // 自定义 Y 轴每个刻度的步长
        },      
      }
    },
  };
    
  const dailyOrderAmountDataSet = {
    labels: dailyLabel,
    datasets: [
      {
        label: sysText802008,
        data: dailyLabel.map((row, index) => dailyOrderAmountData[index]),
        borderColor: "rgba(153, 102, 255, 0.8)",
        backgroundColor: "rgba(153, 102, 255, 0.5)",
      }     
    ],
  };



  function timeToStringHour(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  function timeToStringDay(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数
    const day = String(date.getDate()).padStart(2, '0');   
  
    return `${year}-${month}-${day}`;
  } 

  useEffect(() => {   

    const fetchMerchant = async() => {
      
      setIsLoading(true)     
      
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };

      try {
        const { listResponse, status, statusText } = await web_133(filterData);        
        
        

        if (status === 200) {                
          setGatewayData(listResponse)          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }  

    fetchMerchant()    
  }, [isRefresh]);

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      gateway_id: selectGateway,
      timestamp_30days: new Date().getTime(),
      timestamp_0days: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)).getTime(), // 减去 30 天的毫秒数
      timestamp_24h: new Date().getTime(),
      timestamp_0h: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).getTime(), // 减去 24 小时的毫秒数.getTime()
      getType: selectType
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_135(filterData);        
        
        
        if (status === 200) {    
          
          //設置刷新按鈕時間
          setIsTiming(true)
          setTimeLeft(10 * 1000)

          //24小時
          
          // 假设当前小時时间
          const currentHour = new Date(new Date().setMinutes(0, 0, 0))       

          // 计算 24 小时前的时间
          const hours24Ago = new Date(currentHour.getTime() - (24 * 60 * 60 * 1000))

          // 创建新数组
          let hourLabelList = []
          let hourOrderCountList = []
          let hourOrderAmountList = []
          let hourOrderFailCountList = []          

          // 遍历每一个小时，直到当前时间
          for (let i = hours24Ago; i <= currentHour; i.setHours(i.getHours() + 1)) {
            // 格式化时间为字符串
            const timeString = timeToStringHour(i)            
            // 默认值
            let orderCount = 0;
            let orderAmount = 0;
            let orderFailCount = 0;
            
            // 查找 order_data_24h 中有沒有值
            const matchingEntry = listResponse.order_data_24h.find(entry => entry[0] === timeString);
            if (matchingEntry) {
              orderCount = matchingEntry[1];
              orderAmount = matchingEntry[2];
            }
            hourLabelList.push(timeString.slice(5, 10) + " " + timeString.slice(11, 16))
            hourOrderCountList.push(orderCount)
            hourOrderAmountList.push(orderAmount)

            const matchingEntryFail = listResponse.fail_hour_order.find(entry => entry[0] === timeString);
            if (matchingEntryFail) {
              orderFailCount = matchingEntryFail[1];              
            }
            hourOrderFailCountList.push(orderFailCount)          
          }        

          setHourLabel(hourLabelList)
          setHourOrderCountData(hourOrderCountList)
          setHourOrderAmountData(hourOrderAmountList)
          setHourOrderFailCountData(hourOrderFailCountList)

          //30天

          // 假设当前小時时间
          const currentDay = new Date()       

          // 计算 24 小时前的时间
          const day30Ago = new Date(currentDay.getTime() - (30 * 24 * 60 * 60 * 1000))

          // 创建新数组
          let dailyLabelList = []
          let dailyOrderCountList = []
          let dailyOrderAmountList = []
          let dailyOrderFailCountList = []          

          // 遍历每一个小时，直到当前时间
          for (let i = day30Ago; i <= currentDay; i.setDate(i.getDate() + 1)) {
            // 格式化时间为字符串
            const timeString = timeToStringDay(i)            
            // 默认值
            let orderCount = 0;
            let orderAmount = 0;
            let orderFailCount = 0;
            
            // 查找是否在数组 order_data_30days 中
            const matchingEntry = listResponse.order_data_30days.find(entry => entry[0] === timeString);
            if (matchingEntry) {
              orderCount = matchingEntry[1];
              orderAmount = matchingEntry[2];
            }
            dailyLabelList.push(timeString)
            dailyOrderCountList.push(orderCount)
            dailyOrderAmountList.push(orderAmount)

            const matchingEntryFail = listResponse.fail_order.find(entry => entry[0] === timeString);
            if (matchingEntryFail) {
              orderFailCount = matchingEntryFail[1];              
            }
            dailyOrderFailCountList.push(orderFailCount)    
           
          }        

          setDailyLabel(dailyLabelList)
          setDailyOrderCountData(dailyOrderCountList)
          setDailyOrderAmountData(dailyOrderAmountList)
          setDailyOrderFailCountData(dailyOrderFailCountList)

        }else if(status === 429) {          
          showToast("warn", `${sysText802011}` )
        }else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )          
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
   
  }, [isRefresh, selectGateway, selectType]);

  useEffect(() => {  
    if(isTiming) {
      if(timeLeft > 0) {
        clearInterval(intervalId);
        const id = setInterval(() => {
          setTimeLeft((timeLeft) => timeLeft - 1000);
        }, 1000);
        setIntervalId(id);  
      }else {
        setIsTiming(false)
      }    
  
      return () => {
        clearInterval(intervalId);
      };
    } 
   
  }, [timeLeft, isTiming]);

   const seconds = (timeLeft / 1000).toFixed(0);

  return (
    <div className="cardSettingWrap flex flex-row h-full">
      
      <div className='flex w-[15%] min-w-[150px] flex-col max-md:hidden'>
      <div className='p-2 text-center bg-customColor-bgDark border border-yellow-400 mb-4'>{sysText806001}</div>
        <div className='flex w-full bg-customColor-bgLightGray overflow-auto'>
          
          <ul className='flex flex-col w-full  space-y-2'>
            <li className={`${selectGateway === "" ? "text-yellow-300" : ""} flex justify-center items-center p-2 hover:bg-customColor-bgDark hover:text-yellow-300 duration-150 cursor-pointer`} onClick={() => !isTiming ? setSelectGateway("") : showToast("warn", `请等待刷新秒數(${seconds})，再试一次` )}>
              <p className="mx-2">{sysText806002}</p>
            </li>
            {gatewayData.map(item => {
              return (
                <li key={item.id} className={`${selectGateway === item.id ? "text-yellow-300" : ""} p-2 hover:bg-customColor-bgDark hover:text-yellow-300 duration-150 cursor-pointer`} onClick={() => !isTiming ? setSelectGateway(item.id) : showToast("warn", `请等待刷新秒數(${seconds})，再试一次` )}>
                  <p className="mx-2">{item.display_name}</p>                  
                </li>
              )
            })}
            
          </ul>
        </div>
      </div>

      <div className='flex flex-col w-[85%] max-md:w-full'>   
        <div className='flex flex-row justify-center items-center'>
          <Button label={isTiming ? `${sysText10007}(${seconds})` : sysText10007} disabled={isTiming} className='whitespace-nowrap w-20' severity='success' onClick={() => {setIsRefresh(prev => (!prev))}}></Button>
          <div className='absolute right-4'>            
            <Dropdown className='custom-dropdown' value={selectType} onChange={(e) => setSelectType(e.value)} options={typeOption} disabled={isTiming}></Dropdown>
          </div>
        </div> 
       
        <div className="px-2 tableWrap overflow-auto w-full h-full max-md:hidden">  
          {selectType === "1" && 
            <>
              <Line options={hourOrderCountOptions} data={hourOrderCountDataSet} />
              <Line options={hourOrderAmountOptions} data={hourOrderAmountDataSet} />                    
            </>
          }
          
          {selectType === "2" && 
            <>
              <Line options={dailyOrderCountOptions} data={dailyOrderCountDataSet} />
              <Line options={dailyOrderAmountOptions} data={dailyOrderAmountDataSet} />                 
            </>
          }         
                      
        </div>  

        <div className="px-2 tableWrap overflow-auto w-full h-full min-md:hidden"> 
        {selectType === "1" && 
          <>
            <div className='w-full h-[400px]'>
              <Line options={hourOrderCountOptions} data={hourOrderCountDataSet} />        
            </div>   
            <div className='w-full h-[400px]'>
              <Line options={hourOrderAmountOptions} data={hourOrderAmountDataSet} />
            </div> 
          </>       
        }      
        {selectType === "2" && 
          <>
            <div className='w-full h-[400px]'>
              <Line options={dailyOrderCountOptions} data={dailyOrderCountDataSet} />
            </div>
            <div className='w-full h-[400px]'>
              <Line options={dailyOrderAmountOptions} data={dailyOrderAmountDataSet} />
            </div>     
          </>
        }     
        </div>      
      </div>
    </div>
  );
}

export default GatewayMonitoring