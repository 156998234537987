import React, { useState, useEffect, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import ImgInput from '../../UI/imgInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { uploadfile, web_17_add_staffcardnumber, web_17_ncc_add_info, web_17_ncc_gateway_list, web_17_ncc_gateway_type } from '../../../api/api';
import { useFormik } from 'formik';
import AddBankName from '../bankKeyword/addBankName';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import useSystemText from '../../../helper/useSystemText';

const AddCardNcc = (props) => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {setShowAddCard} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [gatewayTypeOption, setGatewayTypeOption] = useState([])
  const [gatewayOption, setGatewayOption] = useState([])
  const [fileImg1, setFileImg1] = useState()  
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  
  const sysText701003 = useSystemText(701003)
  const sysText701004 = useSystemText(701004)
  const sysText701005 = useSystemText(701005)
  const sysText701006 = useSystemText(701006)
  const sysText701017 = useSystemText(701017)
  const sysText701018 = useSystemText(701018)
  const sysText701011 = useSystemText(701011)
  const sysText701020 = useSystemText(701020)
  const sysText701021 = useSystemText(701021)
  const sysText701037 = useSystemText(701037)
 
  const formik = useFormik({
    initialValues: {
        selectGatewayType: {id: "-1"},
        selectGateway: [],
        gatewayFormData: {},        
        payeeName: "",
        paymentAmountDayLimit: 0,
        singlePaymentUpperLimit: 0,
        singlePaymentLowerLimit: 0        
    },
    validate: (data) => {
        let errors = {};
        const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/  
        const payeeNameRegExp = /^.{1,10}$/   

        if(!data.selectGatewayType) {								
          errors.selectGatewayType = '必填';
        }

        if(Object.keys(data.gatewayFormData).length > 0) {
          Object.keys(data.gatewayFormData).map(key => {
            if(!data.gatewayFormData[key]) {
              errors.gatewayFormData = "必填";
            }
          })
        }

        if(!payeeNameRegExp.test(data.payeeName)) {								
          errors.payeeName = '不可超过10个字';
        }      
      

        if(data.paymentAmountDayLimit === null) {								
          errors.paymentAmountDayLimit = '必填';
        }else if(!numberRegExp.test(data.paymentAmountDayLimit)) {
          errors.paymentAmountDayLimit = "必須為數字"
        }  

        if(data.singlePaymentUpperLimit === null) {								
          errors.singlePaymentUpperLimit = '必填';
        }else if(!numberRegExp.test(data.singlePaymentUpperLimit)) {
          errors.singlePaymentUpperLimit = "必須為數字"
        }         
       
        if(data.singlePaymentLowerLimit === null) {								
          errors.singlePaymentLowerLimit = '必填';
        }else if(!numberRegExp.test(data.singlePaymentLowerLimit)) {
          errors.singlePaymentLowerLimit = "必須為數字"
        }  

        // if(!fileImg1) {
        //   errors.fileImg1 = "请先上传图片"
        // }

        return errors;
    },
    onSubmit: async(data) => {    
      const uploadHandler = async(fileImg) => {
        setIsLoading(true)
        const filterData = {
          fileImg: fileImg          
        }              
           
        try {
          const { listResponse, status, statusText } = await uploadfile(filterData);        
 
          if (status === 200) {
            // 將API返回的URL傳回給父組件
            return listResponse.url                      
                      
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
            showToast("error", "上傳圖片失敗")
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
          // setFile(null);
          // setPreview(null);
          // event.target.value = null;
        }
      }
      //等待圖片上傳 並回傳URL
      let img_url = ""
      if(fileImg1) {
        img_url = await uploadHandler(fileImg1)
      }            
      
      const addCard = async () => {  
        
        setIsLoading(true)

        const gateway = data.selectGateway.map(item => item.id)
        const filterData = {     
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          staffname: Cookies.get("username"),         
          info: JSON.stringify(data.gatewayFormData),
          payee_name: data.payeeName,
          paymentamountdaylimit: data.paymentAmountDayLimit,
          single_payment_upper_limit: data.singlePaymentUpperLimit,
          single_payment_lower_limit: data.singlePaymentLowerLimit,         
          img_url: img_url,
          gateway_type_id: data.selectGatewayType.id,
          gateway: gateway + "",
          
        };    
        
        try {
          
          const { listResponse, status, statusText } = await web_17_ncc_add_info(filterData);       
          
          
  
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            setShowAddCard(false)              
            showToast("success", "" + status, `${sysText701037}` ) 
            formik.resetForm();           
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      addCard()     

      
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  //info 帳戶錢包欄位用
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue("gatewayFormData", {...formik.values.gatewayFormData, [name]: value });
  };

  useEffect(() => {    

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    }
    
    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_17_ncc_gateway_type(filterData);        
        
        

        if (status === 200) {          
          setGatewayTypeOption(listResponse)            
                   
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [isRefresh]);

  useEffect(() => {    
    formik.setFieldValue("gatewayFormData", {})
    if(Object.keys(formik.values.selectGatewayType).length > 0) {
      const fields = formik.values.selectGatewayType.需要欄位 ? JSON.parse(formik.values.selectGatewayType.需要欄位) : {}
      const data = Object.keys(fields).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
      formik.setFieldValue("gatewayFormData", data)
    }    
    
    const fetchGateWayData = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        gateway_type: formik.values.selectGatewayType.id      
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_17_ncc_gateway_list(filterData);        
        
        

        if (status === 200) {          
          setGatewayOption(listResponse)            
                   
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchGateWayData()
    formik.setFieldValue("selectGateway", [])
    
  }, [isRefresh, formik.values.selectGatewayType]);

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[600px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-lg'>
          <form className='flex flex-col' onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-700 p-4 text-lg'>{sysText701017}</div>
                    
            <div className='space-y-4 text-sm my-2 px-8 py-4 h-[60vh] overflow-y-scroll'>

              <label htmlFor='selectGatewayType'>{sysText701003}</label>
              <div className='flex flex-row items-center space-x-4'>                  
                <Dropdown filter name="selectGatewayType" className='custom-dropdown' value={formik.values.selectGatewayType} onChange={formik.handleChange} options={gatewayTypeOption} filter optionLabel="名稱" placeholder="请选择通道类型"/>
                
              </div>

              <div className='space-x-4'>
                
                {/* 根據選擇的通道類型 取出需要的欄位建立input */}
                {Object.keys(formik.values.gatewayFormData).length > 0 &&           
                <>    
                  <label htmlFor='info'>{sysText701005}</label>
                  {Object.entries(formik.values.gatewayFormData).map(([key, value]) => (                  
                      <div key={key}>
                        <label htmlFor={key}>{key}</label>
                        <input name={key} value={formik.values.gatewayFormData[key]} autoComplete='off' onChange={handleInputChange} className='w-full' type="text" />
                        {getFormErrorMessage("gatewayFormData")}
                      </div>                 
                  ))}
                </>        
                }       
              </div>

              <label htmlFor='selectGateway'>{sysText701004}</label>
              <div className='flex flex-row items-center space-x-4'>                  
                <MultiSelect key={isRefresh} filter name="selectGateway" className='custom-dropdown' value={formik.values.selectGateway} onChange={formik.handleChange} options={gatewayOption} filter optionLabel="名稱" placeholder="请选择通道"/>                
              </div>

              <div>
                <label htmlFor='payeeName'>{sysText701006}</label>
                <input name="payeeName" value={formik.values.payeeName} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('payeeName')}
              </div>             

              <div>
                <label htmlFor='paymentAmountDayLimit'>{sysText701018}</label>
                <input name="paymentAmountDayLimit" value={formik.values.paymentAmountDayLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('paymentAmountDayLimit')}
              </div>

              <div>
                <label htmlFor='singlePaymentUpperLimit'>{sysText701020}</label>
                <input name="singlePaymentUpperLimit" value={formik.values.singlePaymentUpperLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text"/>
                {getFormErrorMessage('singlePaymentUpperLimit')}
              </div>

              <div>
                <label htmlFor='singlePaymentLowerLimit'>{sysText701021}</label>
                <input name="singlePaymentLowerLimit" value={formik.values.singlePaymentLowerLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text"/>
                {getFormErrorMessage('singlePaymentLowerLimit')}
              </div>        

              <div>
                <label htmlFor='fileImg'>{sysText701011}</label>
                <ImgInput fileImg1={fileImg1} setFileImg1={setFileImg1}/>
                {/* {!fileImg1 ? <small className="p-error">请先上传图片</small> : <small className="p-error">&nbsp;</small>} */}
              </div>

            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowAddCard(false)}></Button>
              <Button style={{width: "120px"}} label={sysText10002} severity='info' type="submit" raised></Button>
            </div>
          </form>
         
        </div>
       
      </Dialog>
    </>
  );
};

export default AddCardNcc;